import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/icons/chevron-down.svg";
import { useEffect, useState } from "react";
import { IaddressControlAttributes } from "../../../App.types";
import HelpDialog from "../../common/HelpDialog";
import { Title } from "@mui/icons-material";
import "./SingleAddressField.css";

type SingleFieldProps = {
  name: string,
  id: string,
  title: string,
  placeholderText: string,
  isAddtional: boolean,
  handleShowIsAddtional: any,
  altTitle: string,
  address1Value:string,
  address1EventChangeHandler: any,
  address2Value:string,
  address2EventChangeHandler: any,
  disabled:boolean;
}

export default function SingleAddressField({ name, id, title, placeholderText, isAddtional,handleShowIsAddtional, altTitle,address1Value,address1EventChangeHandler,address2Value,address2EventChangeHandler,disabled }: SingleFieldProps) {


  let text1 = "Please provide the full address of the prospective subawardee to include any of \n";
  text1 += "the following details as they apply:\n";
  text1 += " - Address - where residence exists\n";
  text1 += " - Country - where residence exists\n";
  text1 += " - City - name of city\n";
  text1 += " - Province/Region - enter one, either the name of the province or the region or \n";
  text1 += "the state\n";
  text1 += " - Zip Code - zip code of residence ";
  
  return (
    <>
      <div className="flex-fill me-3">
        <label className="label-div ms-2 ps-0 asterisk mb-1" htmlFor={id}>{title} </label>
        <HelpDialog
          title={"Address of Residence"}
          context={text1} />
        <textarea
          style={{ width: "100%", border: "1px solid lightgray", borderRadius:"12px" }}
          id={id}
          name={name}
          placeholder={placeholderText}
          rows={4}
          cols={60}
          onChange={address1EventChangeHandler}
          value={address1Value }
          disabled={disabled}
        />
      </div>
      <span
        style={{ color: "#0164B(" }}
        onClick={() => handleShowIsAddtional(true)}
      >
        <div className="text-end mt-1 me-3 mb-3">
          <span style={{ color: "#0164B9" }}>
            + Add Alternate {title}
          </span>
        </div>
  
      </span>

      {isAddtional && (
        <>
          <div className="flex-fill me-3">
            <div className="text-end fw-normal me-2 mt-1 mb-1">
              <label className="row ms-1 ps-0 mb-1" htmlFor={id}>  {altTitle} </label>
            </div>
            <textarea
              style={{ width: "100%", border: "1px solid lightgray", borderRadius:"12px" }}
              id={id}
              name={name}
              placeholder={placeholderText}
              rows={4}
              cols={60}
              value={address2Value}
              onChange={address2EventChangeHandler}
              disabled={disabled}
            />
          </div>
          <div className="col-12 text-end me-5 pe-2 mt-1">
            <span
              style={{ color: "#0164B(" }}
              onClick={() => handleShowIsAddtional(false)}
            >
              <div style={{ color: "#0164B9" }} className="me-3">
                - Remove Alternate {title}
              </div>
            </span>
          </div>

        </>
      )}
    </>

  );
}
