import "bootstrap/dist/css/bootstrap.min.css";
import { useState,useEffect } from "react";
import "./OrganizationInfo.css";
import { IcontrolParentCompanyAttribs } from "../../../App.types";
import HelpDialog from "../../common/HelpDialog";

export default function ParentCompanyName(prop: {
  title: string;
  controlAttributes: IcontrolParentCompanyAttribs;
  disabled: boolean;
}) {
  var [showAdditionalParentName, setshowAdditionalParentName] = useState(prop.controlAttributes.showSecondText);

  let text = "Name of the company with the controlling interest over the \n";
  text += "organization to receive the award or other assistance.\n";
  text += 'if none please list "N/A."'
  return (
    <>
      <div className="textFormat row">
        <div className="col">
          <label className="label-div asterisk mb-1">{prop.title}</label>
          <HelpDialog
            title={"Parent Company Name"}
            context={text}
          />
        </div>
      </div>
      <div className="control-div">
        <input className="form-control input-group-lg "
          maxLength={100}
          type="text"
          placeholder="Enter Parent Company Name"
          onChange={prop.controlAttributes.primaryOnChange}
          value={prop.controlAttributes.primaryTextValue}
          disabled={prop.disabled}
        />
      </div>
      <div className="col label-div text-end me-4 mt-1">
        <span
          style={{ color: "blue" }}
          onClick={() => setshowAdditionalParentName((show) => (show = true))}
        >
          <div className="me-0">
            <span style={{ color: "#0164B9" }}>
              + Add Parent Company Name
            </span>
          </div>

        </span>
      </div>

      {showAdditionalParentName && (
        <>
          <div className="textFormat row">
            <div className="col ms-4">
              <label className="label-div mb-1 ms-0">Additional Parent Company Name</label>
            </div>
          </div>
          {/* <div className="row control-div"> */}
          <div className="ms-3">
            <input className="form-control input-group-lg"
              maxLength={100}
              type="text"
              placeholder="Enter Addtional Parent Company Name"
              onChange={prop.controlAttributes.secondaryOnChange}
              value={prop.controlAttributes.secondaryTextValue}
              disabled={prop.disabled}
            />
          </div>


          <div className="col label-div text-end mt-0  mt-1">
            <span
              style={{ color: "blue" }}
              onClick={() =>
                // setshowAdditionalParentName((show) => (show = false))
                setshowAdditionalParentName(false) 
              }
            >
              <div className="ms-auto" style={{ color: "#0164B9" }}>
                - Remove Additional Parent Company Name
              </div>
            </span>
          </div>
        </>
      )}
    </>
  );
}
