import { useState } from "react";
import "./OrganizationInfo.css";
import { IcontrolAttributes } from "../../../App.types";

export default function AdditionalLicense(prop: {
  title: string;
  controlAttributes: IcontrolAttributes;
  disabled: boolean;
}) {
  var [showAdditionalLicense, setshowAdditionalLicense] = useState(false);
  //TODO: add useEffect logic here with if then statement

  return (
    <>
      <div className="textFormat row">
        <div className="col-7">
          <label className="mt-3 mb-1">{prop.title}</label>
        </div>
      </div>
      <div className="row control-div">
        <input
          className="form-control input-group-lg"
          placeholder="Enter Professional Licenses & Certifications"
          maxLength={4000}
          type="texbox"
          onChange={prop.controlAttributes.primaryOnChange}
          value={prop.controlAttributes.primaryTextValue}
          disabled={prop.disabled}
        />
      </div>
      <div className="col-4 label-div ms-auto mt-0">
        <span
          style={{ color: "blue" }}
          onClick={() => setshowAdditionalLicense((show) => (show = true))}
        >
          <div className="me-3 mt-1 text-end" style={{color:"#0164B9"}}> 
            + Add Licenses/Certs
          </div>
        </span>
      </div>

      {showAdditionalLicense && (
        <>
          <div className="textFormat row">
            <div className="col-7">
              <label className="mt-1 mb-1 text-start">{prop.title}</label>
            </div>
          </div>
          <div className="row control-div">
            <input
              className="form-control input-group-lg"
              type="texbox"
              onChange={prop.controlAttributes.secondaryOnChange}
              value={prop.controlAttributes.secondaryTextValue}
              disabled={prop.disabled}
            />
            <div className="col-4 label-div ms-auto mt-1 pe-0">
              <span
                style={{ color: "blue" }}
                onClick={() =>
                  setshowAdditionalLicense((show) => (show = false))
                }
              >
                <div className="text-end" style={{color:"#0164B9"}}> 
                  - Remove Licenses/Certs
               </div>
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}
