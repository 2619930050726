import IAddress from "./IAddress";
import IDocument from "./IDocument";

    

/*

*
"pifId": "PIF-100-4",
    "orgId": "ORG-102-1",
    "objectName": "ORG-102-1",
    "organizationName": "Save the planett",
    "parentCompanies": [
        "Jacks Cleaning Company"
    ],
    "identificationType": "Test",
    "isUsOrganization": true,
    "organizationType": "Branch",
    "identificationNumber": "232132",
    "identificationIssuedBy": "Albania",
    "missionName": null,
    "bureau": null
*
    
*/
export default interface IOrganization{
    objectId?: string;
    pifId: string;
    orgId: string;
    objectName: string;
    mission: string;
    organizationName: string;
    bureau: string;
    parentCompanyName: string[];
    parentCompanyId: string[];
    isUsOrganization: boolean | undefined;
    organizationType: string;
    identificationNumber: string;
    identificationIssuedBy: string;
    primaryEmail: string;
    alternativeEmail: string;
    website: string;
    primaryCountryCode: string;
    alternativeCountryCode: string;
    primaryPhoneNumber: string;
    alternativePhoneNumber: string;
    primaryAddress: string;
    alternateAddress: string;
    identificationType: string;
    documents?: File[]
}

const organization:IOrganization={
    objectId:"",
    pifId: "",
    orgId:"",
    objectName:"",
    mission:"",
    bureau:"",
    organizationName:"",
    parentCompanyName: [],
    parentCompanyId: [],
    isUsOrganization: undefined,
    organizationType: "",
    identificationNumber:"",
    identificationIssuedBy: "",
    primaryEmail: "",
    alternativeEmail: "",
    website: "",
    primaryCountryCode: "",
    alternativeCountryCode: "",
    primaryPhoneNumber: "",
    alternativePhoneNumber: "",
    primaryAddress: "",
    alternateAddress: "",
    identificationType: "",
}

export const initialiseIOrganization = ()=>organization;