import { useEffect, useState } from "react";
import "./OrganizationInfo.css";
import { IcontrolAttributes } from "../../../App.types";
import HelpDialog from "../../common/HelpDialog";
import { useCountryStore } from "../../stores/CountryStore";

export default function AdditionalIsCellPhoneNumber(prop: {
  btnName:string;
  title: string;
  isCell: string;
  onIsCellChange: any;
  isAddtionalCell: string;
  onIsAddtionalCellChange: any;
  controlAttributes: IcontrolAttributes;
  isAddNumberNeeded: boolean;
  isPrimary: boolean;
  disabled: boolean;
}) {
  var [showAdditionalPhoneNumber, setshowAdditionalPhoneNumber] =
    useState(false);

  const [isMobile, setMobile] = useState("");

  const handleMobile = (e:any):void => {
    setMobile(e.target.value);
  }

  const {countries,updateCountries} = useCountryStore();
  // remove countries with not country codes
  const filteredCountryCode =  countries?.filter(x=>x.country_code);

  useEffect(()=>{
    if (countries.length===0) updateCountries()
  },[])

  let primaryPhoneText = 'Complete all of the following fields:\n';
      primaryPhoneText += 'Cell - choose Yes if the phone number is a cellphone number and\n';
      primaryPhoneText += 'choose No if its not a cellphone\n';
      primaryPhoneText += 'include country code, area/city code and full phone number';

  let altPhoneText = "Choose Yes if its a cellphone, Choose No if it's not a cellphone\n";
      altPhoneText += 'include country code, area/city code and full phone number';
  return (
    <>
      <div className="textFormat row">
        <div className="col-6 ms-1 px-0">
          <label className="label-div asterisk form-label mb-0">
            {prop.title}
          </label>
          <HelpDialog
                title= {prop.isPrimary ? "Primary Personal Phone Number" : "Alternative Personal Phone Number"}
                context={prop.isPrimary ? primaryPhoneText : altPhoneText}
              />
        </div>
     </div>

        {/* <div className="col-7"> */}
          <div className="row flex-nowrap">

            <div className="col-1 ms-0 ps-1 me-0">
              <label className="form-label label-div asterisk">Cell</label>
            </div>
            <div className="col-3">
              <input
                id={prop.btnName}
                style={{ verticalAlign: "bottom", marginTop: "1em"}}
                className="label-div"
                type="radio"
                name={prop.btnName}
                value={"Y"}
                defaultChecked={prop.btnName === 'Y'}
                onChange={prop.onIsCellChange}
                disabled={prop.disabled}
              />
              <label htmlFor={prop.btnName}>Yes</label>
              <input
                id={prop.btnName}
                type="radio"
                style={{ marginLeft: "1em" }}
                name={prop.btnName}
                value={"N"}
                defaultChecked={prop.btnName === 'N'}
                onChange={prop.onIsCellChange}
                disabled={prop.disabled}
              />
              <label htmlFor={prop.btnName}>No</label>
            </div>
        {/* </div> */}
      {/* <div className="row" > */}
        <div className="col-4">
          <select className="form-select" style={{ width: "90%" }}
          disabled={prop.disabled}
          ><option selected>Code</option>
            {filteredCountryCode.map((options, i) => (
                    <option key={i} value={options.country_code}>
                      {options.country_name +" (" + options.country_code +")"}
                    </option>
            ))}
          </select>
        </div>
        <div className="col-3 control-div mx-0 px-0">
          <input
            style={{width:"80%"}}
            className="mt-0 border-1 border-light-subtle form-control input-group"
            placeholder="000-000-0000"
            type="text"
            onChange={prop.controlAttributes.primaryOnChange}
            value={prop.controlAttributes.primaryTextValue}
            disabled={prop.disabled}
          />
        </div>
      </div>
      {prop.isAddNumberNeeded && (
      <div className="row label-div mt-0">
        <span
          style={{ color: "blue" }}
          onClick={() => setshowAdditionalPhoneNumber((show) => (show = true))}
        >
          <div className="text-end me-5 mb-2 mt-0 pe-3">+ Add Additional Number</div>
        </span>
      </div>
      )}

      {showAdditionalPhoneNumber && prop.isAddNumberNeeded && (
        <>
          <div className="textFormat row">
            <div className="col-5">
              <label className="label-div asterisk form-label mb-0">Alternate Phone Number</label>
            </div>
            <HelpDialog
                title={"Alternative Phone Number"}
                context={altPhoneText}
              />
            <div className="col-7">
              <div className="row">
                <div className="col-3 ms-n2 ps-5">
                  <label className="form-label label-div">Cell</label>
                </div>
                <div className="col-6 d-line">
                  <input
                    style={{ verticalAlign: "bottom", marginTop: "1em" }}
                    type="radio"
                    name="additionalCellPhone"
                    value={"Y"}
                    checked={prop.isAddtionalCell === "Y"}
                    onChange={prop.onIsAddtionalCellChange}
                    disabled={prop.disabled}
                  />
                  <label>Yes</label>
                  <input
                    type="radio"
                    style={{ marginLeft: "1em" }}
                    name="additionalCellPhone"
                    value={"N"}
                    checked={prop.isAddtionalCell === "N"}
                    onChange={prop.onIsAddtionalCellChange}
                    disabled={prop.disabled}
                  />
                  <label>No</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5 control-div">
              <select className="form-select" 
                      style={{ width: "100%" }}
                      disabled={prop.disabled}
              >
            <option selected>Code</option>
            {filteredCountryCode.map((options, i) => (
                    <option key={i} value={options.country_code}>
                      {options.country_name +" (" + options.country_code +")"}
                    </option>
            ))}
              </select>
            </div>
            <div className="col-5 control-div">
              <input
                className="form-control input-group-lg"
                placeholder="000000000-0000"
                type="text"
                onChange={prop.controlAttributes.secondaryOnChange}
                value={prop.controlAttributes.secondaryTextValue}
                disabled={prop.disabled}
              />
            </div>
          </div>
          {prop.isAddNumberNeeded && (
          <div className="row label-div mt-0 text-end pe-1 me-5">
            <span
              style={{ color: "blue", }}
              onClick={() =>
                setshowAdditionalPhoneNumber((show) => (show = false))
              }
            >
              - Remove Number
            </span>
          </div>
          )}
        </>
      )}
      
    </>
  );
}
