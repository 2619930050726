import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './AwardInfo.css';
import axios from "axios";
import { Dropdown} from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import HelpDialog from '../../common/HelpDialog';
import validateFields from './validations/AwardInfoValidations';
import { Errors } from './validations/AwardInfoValidations';
import { DatePicker } from 'rsuite';
import { format, parseISO, isValid } from 'date-fns';
import { useAwardeeStore } from '../../stores/AwardeeStore';
import { RouteConstants } from '../../../App';
import { useNavStore } from '../../stores/NavStore';
import { createPif } from '../../../Services/Api';
import ReturnedLabel from '../../common/ReturnedLabel/ReturnedLabel';
import { v4 as uniqueKey } from "uuid";
import { useFormProps } from '../../stores/FormProperties';
import { ISubAward } from '../../../Interfaces/ISubAward';
import { useCountryStore } from '../../stores/CountryStore';


axios.defaults.withCredentials=true;


function AwardInfo(props: any) {

  const [submissionType, setSubmissionType] = useState("SUB"); //Possible SUB,PRIME,NONAWARD
  const [showPrimeAward, setShowPrimeAward] = useState(false);
  const [showNonAward, setShowNonAward] = useState(false);
  const [showSubAward, setShowSubAward] = useState(true)
  

  const pifId = useRef<string>("");
  const pifStatus = useRef<string>("");
  const returnedReason = useRef<string>("");
  // const [primeList, setPrimeList] = React.useState<string[]>([]);
  const { state, pathname } = useLocation();

  const { storeFormType, updateNavStore, awardType, getRoute } = useNavStore();
  const { updatePifId } = useFormProps();
  const {countries,updateCountries} = useCountryStore();
  
  useEffect(()=>{
    if (countries.length===0) updateCountries()
  },[])

  const basePath = process.env.REACT_APP_API_URL;

  const [primeOptions, setPrimeOptions] = React.useState<Array<{
    awardObjectId: string,
    projectName: string,
    awardee: string,
    awardeeId: string,
    mission: string,
    orgId: string,
    bureau: string
  }>
  >([])



  const [awardTypeOptions, setVettingCategoryOptions] = React.useState<Array<{
    picklist_name: String,
    picklist_id: string,
    is_active: string,
    description: string,
    display_name: string,
    picklist_category_id: string
  }>
  >([])

  const navigate = useNavigate();
  const [subAward, setSubAward] = React.useState<Array<ISubAward>
  >([])

  const [formErrors, setFormErrors] = useState<Errors>({
    primeAward: '',
    awardType: '',
    subawardValue: '',
    country: '',
    province: '',
    startDate: '',
    endDate: '',
    purpose: '',
  })

  const { disableFormFields, updateMission, updateOrgId , updateBureau, storedMission, storedBureau} = useFormProps();
  //const disableFields = useRef(false); 
  // let [_pifId, setPifID] = useState("");


  // make stateRef always have the current count
  // const pifRef:any = useRef();


  //*Get location
  const urlLolcation = useLocation();
  function getCurrentUrl() {
    return urlLolcation.pathname as string
  }



  const primeListURL: string = basePath + "/primeaward/list";
  const awardTypeURL: string = basePath + "/picklist/picklistByName/award_type"
  const subAwardURL: string = basePath + "/subaward/{pifId}";


  useEffect(() => {
    let inputPifId = state && state.pifId ? state.pifId : ""

    if (inputPifId !== "") {
      pifId.current = inputPifId;
    }
    pifStatus.current = state && state.pifStatus ? state.pifStatus : "";
    returnedReason.current = state && state.returnedReason ? state.returnedReason : "";
    /* Detect WHICH TYPE OF AWARD will likely change in the future */
    if (getCurrentUrl().includes('prime')) {
      setShowPrimeAward(true);
      setSubmissionType("PRIME");
      setShowNonAward(false);
      setShowSubAward(false);
    }
    // Get awardType from navStore & use instad of looking for in url using getCurrentURL()
    if (awardType === 'nonaward') {
      setShowNonAward(true);
      setSubmissionType("NONAWARD");
      setShowPrimeAward(false);
      setShowSubAward(false);
    }
    if (awardType === 'subaward') {
      setShowSubAward(true);
      setSubmissionType("SUB");
      setShowPrimeAward(false);
      setShowNonAward(false);
    }

    let mode = "edit";
    if (pifId.current === "") mode = "new";
    else mode = "edit";

    // TESTING: uncommenting to see the mode and pif ID
    //console.log("my mode is ", mode, "pifId is ", JSON.stringify(props));
    // if (mode === "edit") {
    //   if(global_pifId) tempPifId = global_pifId;
    //   else tempPifId = prompt("pif ID", "");
    // }




    axios.get(primeListURL, { withCredentials: true })
      .then(response => {
        setPrimeOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });


    axios.get(awardTypeURL)
      .then(response => {
        setVettingCategoryOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });

    if (mode === "edit") {
      //prompt("", subAwardURL.replace("{pifId}", tempPifId));
      axios.get(subAwardURL.replace("{pifId}", `${pifId.current}`))
        .then(response => {
          setSubAward(response.data);
          //--//--//
          pifId.current = response.data.pifId;
          setSubAwardObjectName(response.data.subAwardObjectName)
          setSelectedPrimeAwardeeId(response.data.primeAwardeeId)
          setSelectedPrimeContactorOption(response.data.primeAwardee)
          setSelectedOptionAward(response.data.projectName)
          setSelectedOptionAwardType(response.data.awardType)
          setvalueOfTotalSubAward(response.data.awardAmount)
          setSelectedPrimeAwardId(response.data.primeAwardId)
          //TODO: Convert dates to proper format: dd-mmm-yyyy
          if(response.data.endDate){
          const tempEndDate = response.data.endDate;
          const newEndDate: any = convertDate(tempEndDate);
          setEndDate(newEndDate);
          }

          if(response.data.startDate){
          const tempStartDate = response.data.startDate;
          const newStartDate: any = convertDate(tempStartDate);
          setStartDate(newStartDate);
          }

          setValueOfSolicitationAwardNumber(response.data.awardNumber)
          setLocation(response.data.proposedState)
          setSelectedCountryOption(response.data.proposedCountry)
          setPurposeofProposed(response.data.purposeOfSubAward)

          //update store for mission and Associated Org Id
          if(selectedOptionAward?.length){
          let foundPrime = primeOptions.find(prime => prime.projectName=== selectedOptionAward);
          // console.log("Prime ", JSON.stringify(primeOptions))
          // console.log("Selected  ",  selectedOptionAward)
          // console.log("response  ", JSON.stringify(response.data))

          if (foundPrime !== undefined) {
            updateBureau(foundPrime.bureau||"");
            updateMission(foundPrime.mission||"");
            updateOrgId(foundPrime.orgId||"");
          }
        }
          
        })
        .catch(error => {
          console.error('Error fetching options:', error);
        });
    }


    // //set some forms to read-only
    // if(["Submitted - Clarification Requested"].includes(formProperties.vsuStatus)){
    //   const inputs = document.querySelectorAll('input,select,textarea');
    //   inputs.forEach(input=>{
    //     if(input instanceof HTMLInputElement) input.disabled = true;
    //     //else if(input instanceof HTMLInputElement) input.readOnly = true
    //   });

    //   //Disable dropdowns and other fields
    //   disableFields.current = true;
    // }


  }, []); // The empty dependency array ensures that the effect runs only once when the component mounts

  const awardTypes = ['Select an Award', 'Assistance to Household', 'Beneficiary/Assistance', 'Contract', 'Cooperative agreement', 'Correction', 'Cost Amendment', 'Cost Amendment and Time Extension', 'Formal Contract', 'Grant', 'In-Kind Assistance', 'Informal Contract', 'Micro-enterprise loan', 'Purchase order', 'Time Extension/Reduction', 'Trainee'];

  const primeContractorsOrgTypes = ['<Select a Prime Contractor>', 'Non-US', 'Public International Organization', 'US-Organization'];

  const vettingRuleNames = ['Select a Vetting Category', 'Contract', 'Grant', 'Cooperative Agreement', 'Beneficiary Agreement/Assistance', 'Micro-Enterprise Loan', 'Trainee'];



  // const pifValue = useRef<null|string>();


  const [selectedCountryOption, setSelectedCountryOption] = useState<string | null>(null);

  const [selectedPrimeContractorOption, setSelectedPrimeContactorOption] = useState<string | null>(null);

  const [selectedOptionAward, setSelectedOptionAward] = useState<string | null>(null);

   const [selectedPrimeAwardeeId, setSelectedPrimeAwardeeId,] = useState<string | null>(null);

  const [selectedPrimeAwardId, setSelectedPrimeAwardId] = useState<string | null>(null);

  const [selectedAwardType, setSelectedOptionAwardType] = useState<string | null>(null);

  const [validationDisplayMessage, setValidationDisplayMessage] = useState<string>("");

  const [subAwardObjectName, setSubAwardObjectName] = useState<string>("");




  // Prime Awards
  const handleSelectPrimeAward = (eventKey: any) => {
    let foundPrime = primeOptions.find(prime => prime.awardObjectId === eventKey);
    if (foundPrime !== undefined) {
      setSelectedOptionAward(foundPrime.projectName);
      setSelectedPrimeContactorOption(foundPrime.awardee);
      setSelectedPrimeAwardeeId(foundPrime.awardeeId);
      setSelectedPrimeAwardId(foundPrime.awardObjectId);
      updateMission(foundPrime.mission||"");
      updateOrgId(foundPrime.orgId || "");
      updateBureau(foundPrime.bureau ||"")

    }
    else {
      setSelectedOptionAward(null);
      setSelectedPrimeContactorOption(null);
    }
  }

  // User Story 23921
  const handleSelectPrimeContractor = (eventKey: any) => {
    setSelectedPrimeContactorOption(eventKey as string);
  }
  // Prime Awards


  // ***********User Story 23921*******************
  // 
  // const handleRewardChange = (event) => {
  //   setSelectedOptionAward(event.target.value);
  // };

  // const displayPrimeContractor = ({selectedOptionAward}) => {
  // switch (selectedOptionAward) {
  //   case 'Assistance to Household':
  //     return<p>Name of Prime Contractor: Company A</p>;
  //   case 'Beneficiary/Assistance':
  //     return<p>Name of Prime Contractor: Company B</p>;
  //   case 'Contract':
  //       return <p>Name of Prime Contractor: Company C</p>;
  //   case '<Select a Prime Contractor>':
  //     return null;
  // }  default:
  //    return <p>Name of Prime Contractor:</p>;
  // }
  // ***********User Story 23921*******************


  // SubAward
  const [valueOfTotalSubAward, setvalueOfTotalSubAward] = useState<string>('');
  const [isValidTotal, setIsValidTotal] = useState<boolean>(true);

  const HandleTotalInputChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const totalSubAwardValue: string = e.target.value;

    const isValidValueInput: boolean = /^\d*\.?\d{0,2}$/.test(totalSubAwardValue)

    setvalueOfTotalSubAward(totalSubAwardValue);
    setIsValidTotal(isValidValueInput);
  }
  // SubAward

  // Solicitation
  const [valueOfSolicitationAwardNumber, setValueOfSolicitationAwardNumber] = useState<string>('');
  const [isValidSolicitationAwardNumber, setSolicitationAwardNumber] = useState<boolean>(true);

  const HandleSolicitationAwardNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const SolicitationAwardNumber: string = e.target.value;

    const isValidSolicitationAwardNumber: boolean = /^\d*\.?\d{0,2}$/.test(SolicitationAwardNumber)

    setValueOfSolicitationAwardNumber(SolicitationAwardNumber);
    setSolicitationAwardNumber(isValidSolicitationAwardNumber);
  }
  // Solicitation

  //Location
  const [location, setLocation] = useState<string>('');
  const [isValidLocation, setIsValidLocationInput] = useState<boolean>(true);

  const handleInputLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputLocation: string = e.target.value;

    const allowedFormat = new RegExp(/^[A-Za-z\s!@#$%^&*(),.?":{}|<>\/]+$/);

    const isValidInputLocation = allowedFormat.test(inputLocation);

    setLocation(inputLocation);
    setIsValidLocationInput(isValidInputLocation);
  };
  // Location

  // Purpose of Proposed
  const [valueofpurposeofProposed, setPurposeofProposed] = useState<string>('');
  const [isValidPurposeofProposed, setIsValidPurposeofProposed] = useState<boolean>(true);

  const handlePurposeofProposedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputPurposeofProposed: string = e.target.value;

    const allowedFormat = new RegExp(/^[A-Za-z\s!@#$%^&*(),.?":{}|<>]+$/);

    const isValidInputPurposeofProposed = allowedFormat.test(inputPurposeofProposed);

    setPurposeofProposed(inputPurposeofProposed);
    setIsValidPurposeofProposed(isValidInputPurposeofProposed);
  }
  // Purpose of Proposed

  // Country
  const handleSelectCountry = (eventKey: any) => {
    setSelectedCountryOption(eventKey as string);
  }
  // Country

  // Vetting
  const handleSelectAwardType = (eventKey: any) => {
    setSelectedOptionAwardType(eventKey as string);
  }
  // Vetting


  // StartDate
  const [startDate, setStartDate] = useState<Date | null | undefined>();
  const [isValidStartDate, setIsValidStartDate] = useState<boolean>(true);

  const handleStartDateChange = (selectedDate: Date | null | any) => {
    setStartDate(selectedDate);

    // const dateRegex: RegExp = /^(0[1-9]|[1-2][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    // const dateRegex: RegExp = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    // changed to check format: dd-MMM-yyyy
    // const dateRegex: RegExp = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    // const dateRegex: RegExp = /^(\d{1,2})-([A-Za-z]{3})-(\d{4})$/;
    // const isValidFormat: boolean = dateRegex.test(inputValue);
    // const isValidFormat: boolean = dateRegex.test(convertedDate);



    // setIsValidStartDate(isValidFormat);
  }
  // StartDate


  // EndDate
  const [endDate, setEndDate] = useState<Date | null | undefined>();
  const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);

  const handleEndDateChange = (selectedDate: Date | null | any) => {
    setEndDate(selectedDate);

    // const dateRegex: RegExp = /^(0[1-9]|[1-2][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    // const dateRegex: RegExp = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    // const isValidEndDateFormat: boolean = dateRegex.test(inputEndDateValue);

    // setIsValidEndDate(isValidEndDateFormat);
  }
  // EndDate

  function FormComponent() {
    const [valueOfTotalSubAward, setvalueOfTotalSubAward] = useState('');
    // const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
  }

  function performValidation() {

  }

  function handleCancel() {
    navigate(RouteConstants.DASHBOARD);

  }

  // Mandatory Fields:
  // Prime Award
  // Name of Prime Contractor
  // Vetting Category/Vetting Rule Name
  // Value of Total Subaward(USD)
  // Country
  // Start Date
  // End Date
  //Purpose of Proposed Award or Assistance 

  const [inputisValidTotal, setInputisValidTotal] = useState('');

  async function handleSaveAsDraft() {
    if (valueOfTotalSubAward !== '' && !/^\d+(\.\d{1,2})?$/.test(valueOfTotalSubAward)) {
      setInputisValidTotal("Please Enter a Value up to Two Decimal Places.");
    } else {
      setInputisValidTotal("");
      await createPifRequest();
      // alert('Draft Saved');
    }

    navigate(RouteConstants.DASHBOARD);
  }

  async function handleSaveAndCont() {
    if(disableFormFields) navigate(getRoute(pathname));
    // Rearrange after demo
    //always update the Navigation store before navigating plssss


    const errors = validateFields(selectedOptionAward, selectedAwardType, selectedCountryOption,
      valueOfTotalSubAward, location, startDate, endDate, valueofpurposeofProposed);
    setFormErrors(errors);

    // testing errors
    console.log ("ERRORS are: " + errors);

    // Bypass Validation if Prime Award or Non-Award - only one field to check and its in the store
    if ((Object.keys(errors) as Array<keyof Errors>).length === 0 || showNonAward || showPrimeAward ) {
      await createPifRequest().then(() => {
        navigate(getRoute(pathname));
      });
    }

  }


  //Expected input format YYYY/DD/MM converts to MM-DD-YYYY
  function convertDate(inputDate: string) {
    if (inputDate === null || inputDate === '') {
      return "";
    }
    // remove time portion 
    const dateStr = inputDate.slice(0, 10);
    // console.log ("Date String is: " + dateStr);

    // change to date Object
    const dateObj = parseISO(dateStr);
    // console.log ("Date Obj is: " + dateObj);

    // put in correct format
    let newDate = format(dateObj, 'dd-MMM-yyyy');
    // console.log ("New Date from format is: " + newDate);

    // convert back to a Date Object
    const date = new Date(newDate);


    if (!isValid) {
      console.log("Bad Date Format: " + newDate + "Using Default date of 30 Dep 2024");
      newDate = format(parseISO("30-sep-2024"), 'dd-MMM-yyyy');
    }
    // console.log ("Date string in DATE format: " + newDate);
    return date;
  }


  async function createPifRequest() {

    let URL = process.env.REACT_APP_API_URL + "/subaward/createupdate/";
    const requestData = {
      "pifId": pifId.current,   //pif id
      "subAwardObjectName": subAwardObjectName,
      "primeAwardeeId": selectedPrimeAwardeeId,
      "primeAwardee": selectedPrimeContractorOption,
      "awardee": "", //Will ADD ON WHEN AVAIBLE
      "awardeeId": "", //Will ADD ON WHEN AVAIBLE
      "projectName": selectedOptionAward,
      "awardType": selectedAwardType,
      "awardAmount": valueOfTotalSubAward,
      "endDate": endDate,
      "startDate": startDate,
      "awardNumber": valueOfSolicitationAwardNumber,
      "purposeOfSubAward": valueofpurposeofProposed,
      "proposedCountry": selectedCountryOption,
      "proposedState": location,
      "primeAwardId":selectedPrimeAwardId,
      "mission" : storedMission,
       bureau: storedBureau
    }

    createPif(requestData).then(x => {
      if (x) {
        updatePifId(x);
        
        pifId.current = x;
      }
    });

    //prompt("data", JSON.stringify(requestData));



  }

  // if Name of Prime contractor, grantee, or recipient exists set Org globally
  // otherwise set to ""
  const setOrganization = useAwardeeStore((state) => state.setAwardee);
  let orgTitle = '';
  if (selectedPrimeContractorOption === '' || selectedPrimeContractorOption === undefined || selectedPrimeContractorOption === '') {
    setOrganization(orgTitle);
  } else {
    (setOrganization(selectedPrimeContractorOption));
  }


  return (
    <div id="awardInfoContainer">
      {pifStatus.current === "Returned" && <ReturnedLabel comment={returnedReason.current} />}
      {validationDisplayMessage.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          <label style={{ color: "#D31E21" }}>{line}</label>
          <br />
        </React.Fragment>
      ))
      }
      {/*Start of Prime Award Container*/}
      <div id="primeAward">
        <div className="orgTitleSection fw-bold mb-0" style={{ marginLeft: "1.5em" }}>
          {pifId.current && <p className='text-start pif-id'> <strong> PIF ID:</strong> {pifId.current}</p>}
          { showNonAward ? <p className='text-start'>Non-Award Information (Associated Prime Award) </p> :
            <p className='text-start'>Prime Award Information (Associated Prime Award) </p> }
        </div>
        <div className="contentSection textFormat" style={{ marginBottom: "2em" }}>
          <div className="row">
            <label style={{ color: "#D31E21" }}>* Denotes mandatory field</label>
          </div>
          {/* Change Styling here - make box "fatter" */}
          <div className={`row row-cols-2 ${showSubAward ? "regularDiv" : "biggerDiv"}`}>
            <div className="col-6 pe-2">
              <label className="label-div asterisk mb-1">Prime Award</label>
              <HelpDialog
                title={"Prime Award"}
                context='Prime Award name to which the subaward is based'
              />
              <Dropdown onSelect={handleSelectPrimeAward} key={uniqueKey()} tabIndex={1}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" size="lg"
                  style={{ width: '96%', 
                          fontSize: "1rem", 
                          backgroundColor: 'white', 
                          color: 'black', 
                          borderColor: 'lightgrey', 
                          display: 'flex', 
                          marginTop: '1px', 
                          marginLeft: '15px' }}
                  disabled={disableFormFields}>
                  {selectedOptionAward || 'Select a Prime Award'}
                  <span className="caret" style={{ marginRight: 'auto', borderColor: 'lightgrey transparent transparent' }}></span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: '96%', backgroundColor: 'white', }}>
                  {primeOptions.map((option, i) => (
                    <Dropdown.Item key={option.awardObjectId + i} eventKey={option.awardObjectId}>
                      {option.projectName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {formErrors.primeAward && <p className="error">{formErrors.primeAward}</p>}
            </div>
            <div className="col flex-fill pe-5 ps-0">
              <label className="label-div">Name of Prime contractor, grantee, or recipient:</label>
              <div className="ms-3 fw-bold textFormat" style={{ marginTop: '18px' }}>
                <p>{selectedPrimeContractorOption}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End of Prime Award Container*/}

      <div id="subaward" style={{ display: showSubAward ? 'block' : 'none' }}>
        <div className="orgTitleSection fw-bold mb-0" style={{ marginLeft: "1.5em" }}>
          <p className='text-start'>Subaward Information</p>
        </div>
        <div className="contentSection textFormat" style={{ marginBottom: "2em" }}>
          <div className="row">
            <label style={{ color: "#D31E21" }}>* Denotes mandatory field</label>
            <label>{ }</label>
          </div>

          {/* Start of Row 1*/}
          <div className="row row-cols-2">
            <div className="col-6 pe-2">
              <label className="label-div asterisk mb-1">Vetting Category</label>
              <HelpDialog
                title={"Vetting Category"}
                context='Proposed award mechanism'
              />
              <Dropdown onSelect={handleSelectAwardType} key={uniqueKey()} tabIndex={2}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" size="lg"
                  style={{ width: '96%', 
                          fontSize: "1rem", 
                          backgroundColor: 'white', 
                          color: 'black', 
                          borderColor: 'lightgrey', 
                          display: 'flex', 
                          justifyContent: 'flex-end', 
                          marginTop: '1px', 
                          marginLeft: '15px' }}
                  disabled={disableFormFields}
                  >
                  {selectedAwardType || 'Select Vetting Category'}
                  <span className="caret" style={{ marginRight: 'auto', borderColor: 'lightgrey transparent transparent' }}></span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: '96%', backgroundColor: 'white', }} tabIndex={3}>
                  {awardTypeOptions.map((option, i) => (
                    <Dropdown.Item key={option.picklist_id + i} eventKey={option.display_name}>
                      {option.display_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {formErrors.awardType && <p className="error">{formErrors.awardType}</p>}
            </div>


            <div className="col flex-fill pe-5 ps-0">
              <label className="label-div asterisk mb-1">Estimated Value of Total Subaward (USD)</label>
              <HelpDialog
                title={"Value of Total Subaward (USD)"}
                context='Total Estimated value over the life of the sub award'
              />
              <div className="control-div">
                <input tabIndex={4}
                  className="form-control input-group-lg"
                  type="textbox"
                  id="valueOfTotalSubAward"
                  value={valueOfTotalSubAward}
                  onChange={HandleTotalInputChangeValue}
                  style={{ borderColor: isValidTotal ? 'lightgrey' : '#D31E21', color: "black" }}
                  placeholder='Enter Estimated Value of Total Subaward (USD)'
                  maxLength={126} 
                  disabled={disableFormFields}/>
              </div>
              {formErrors.subawardValue && <p className="error">{formErrors.subawardValue}</p>}
              <div>
                {inputisValidTotal && (
                  <p style={{ color: '#D31E21' }}>
                    {inputisValidTotal}
                  </p>
                )}
              </div>
              {/* {!isValidTotal && (
              <p style={{ color: '#D31E21' }}>
                Enter a valid number up to two decimal places.
              </p>
            )} */}
            </div>
          </div>
          {/* End of Row 1*/}


          {/* Start of Row 2*/}
          <div className="row row-cols-2">
            <div className="col-6 pe-2">
              <label className="label-div asterisk mb-1">Country</label>
              <HelpDialog
                title={"Country"}
                context='Country where the work will be performed'
              />
              <Dropdown onSelect={handleSelectCountry} key={uniqueKey()} tabIndex={5}>
                <Dropdown.Toggle variant="success" id="dropdown-country" size="lg"
                  style={{ width: '96%', 
                           fontSize: "1rem", 
                           backgroundColor: 'white', 
                           color: 'black', 
                           borderColor: 'lightgrey', 
                           display: 'flex', 
                           justifyContent: 'flex-end', 
                           marginTop: '1px', 
                           marginLeft: '15px' }}
                  disabled={disableFormFields}
                           >
                  {selectedCountryOption || 'Select a Country'}
                  <span className="caret" style={{ marginRight: 'auto', borderColor: 'lightgrey transparent transparent' }}></span>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: '96%', backgroundColor: 'white', maxHeight: '1000%', overflowY: 'auto' }} className='disabledDropsDowns'>
                  {countries.map((option, i) => (
                    <Dropdown.Item key={i} eventKey={option.country_name}>
                      {option.country_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {formErrors.country && <p className="error">{formErrors.country}</p>}
            </div>

            <div className="col flex-fill pe-5 ps-0">
              <label className="label-div mb-1 asterisk">Province/Region</label>
              <HelpDialog
                title={"Province/Region"}
                context='State or Province or Region where the work will be performed'
              />
              <div className="control-div">
                <input tabIndex={6}
                  className="form-control input-group-lg"
                  type="textbox"
                  maxLength={150}
                  placeholder='Province/Region'
                  id="location" value={location}
                  onChange={handleInputLocationChange}
                  style={{ borderColor: isValidLocation ? 'lightgrey' : '#D31E21' }}
                  disabled={disableFormFields}
                />
              </div>
              {formErrors.province && <p className="error">{formErrors.province}</p>}
              {!isValidLocation && (
                <p style={{ color: '#D31E21', marginRight: 'auto' }}>
                  Enter a valid location format.
                </p>
              )}
            </div>
          </div>
          {/* End of Row 2*/}

          {/* Start of Row 3*/}
          <div className="row row-cols-2">
            <div className="col-6 pe-2">

              <label className="label-div mb-1 asterisk ms-3">Estimated Subaward Start Date</label>
              <HelpDialog
                title={"Estimated Subaward Start Date"}
                context='Date the subaward is scheduled to begin'
              />
              <div className="control-div rounded-3">
                <DatePicker format={('dd-MMM-yyyy')} tabIndex={7}
                  style={{ width: "100%", color: "black", fontFamily: "sans-serif" }}
                  size='md'
                  id='startDateInput'
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="dd-mmm-yyyy"
                  placement='topStart'
                  disabled={disableFormFields}
                />
              </div>
              {formErrors.startDate && <p className="error">{formErrors.startDate}</p>}
              {!isValidStartDate && <p style={{ color: '#D31E21' }}>Invalid date format. Use DD-MMM-YYYY.</p>}
            </div>

            <div className="col flex-fill ps-0 pe-5">
              <label className="label-div mb-1 asterisk">Estimated Subaward End Date</label>
              <HelpDialog
                title={"Estimated Subaward End Date"}
                context='Date the subaward is scheduled to end'
              />
              <div className="control-div">
                <DatePicker format={('dd-MMM-yyyy')} tabIndex={8}
                  style={{ width: "100%", color: "black", fontFamily: "sans-serif" }}
                  size='md'
                  id='endDateInput'
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder="dd-mmm-yyyy"
                  placement='topStart'
                  disabled={disableFormFields}
                />
              </div>
              {formErrors.endDate && <p className="error">{formErrors.endDate}</p>}
              {!isValidEndDate && <p style={{ color: '#D31E21' }}>Invalid date format. Use DD-MMM-YYYY.</p>}
            </div>
          </div>
          {/* End of Row 3*/}


          {/* Start of Row 4*/}
          <div className="row row-cols-2">

            <div className="col-6 pe-2">
              <label className="label-div mb-1">Solicitation/Award Number</label>
              <HelpDialog
                title={"Solicitation/Award Number"}
                context='USAID Solicitation or award number associated with the Prime Award'
              />
              <div className="control-div">

                <input tabIndex={9}
                  style={{ color: "black" }}
                  className="form-control input-group-lg"
                  type="textbox" maxLength={100}
                  id="valueOfSolicitationAwardNumber"
                  value={valueOfSolicitationAwardNumber}
                  onChange={HandleSolicitationAwardNumber}
                  placeholder='Enter Solicitation/Award Number' 
                  disabled={disableFormFields}/>
                
              </div>
            </div>

            <div className="col flex-fill ps-0 pe-5">
              <label className="label-div asterisk mb-1">Purpose of Proposed Award or Assistance</label>
              <HelpDialog
                title={"Purpose of Proposed Award of Assistance"}
                context='Brief description of the activity performed under this subaward'
              />
              <div className="control-div">
                <input type="textbox" tabIndex={10}
                  className="form-control input-group-lg"
                  id="PurposeofProposed"
                  value={valueofpurposeofProposed}
                  onChange={handlePurposeofProposedChange}
                  style={{ borderColor: isValidPurposeofProposed ? 'lightgrey' : '#D31E21' }}
                  placeholder='Purpose of Proposed Award or Assistance' 
                  disabled={disableFormFields}/>

                {!isValidPurposeofProposed && (
                  <p style={{ color: '#D31E21' }}>
                    Enter valid text
                  </p>
                )}
              </div>
              {formErrors.purpose && <div className="error">{formErrors.purpose}</div>}
            </div>
          </div>
          {/* End of Row 4*/}
        </div>

      </div>{/*end subaward}
            
      {/* <div className="mx-auto" id="button-content-area" style={{textAlign: "center"}}>  */}
      <div className="d-flex mb-5 justify-content-center gap-4" id="button-content-area">

        <button type="button" className="btn" onClick={handleCancel} tabIndex={10}>Cancel</button>
        <button type="button" className="btn" tabIndex={10} onClick={
          () => {
            // if(awardType==="primeaward"){
            //   navigate(RouteConstants.PRIME_AWARD_ORGANIZATION)
            // } 
            handleSaveAndCont()
          }}>Save & Continue</button>
      </div>
    </div>
  );
}

export default AwardInfo;