import { create } from "zustand";
import { persist } from 'zustand/middleware';
import { AwardTypes, FormTypes, RouteConstants } from "../../App";
import { StoreNames } from "./StoreNames";
// Create type for custom hook
export type Workflows = 'individual' | 'organization' | null

type NavState = {
    awardType: string;
    storeFormType: string;
    updateNavStore:(formType: string)=>void;
    updateFormType:(formType: string)=>void;
    clearNavStore:()=>void;
    updateAwardType:(awardtype:string)=>void;
    getRoute:(pathname:string)=>string;
};


export const useNavStore = create<NavState>()(
    persist(
        (set,get) => ({
            awardType:"",
            storeFormType: "",
            updateNavStore: (_workflow: string) => set(() => ({ 
                storeFormType: _workflow })),
            clearNavStore:()=>set(()=>({
                storeFormType: "",
                awardType:""})),
            updateFormType: (_workflow: string) => set(() => ({storeFormType:_workflow })),
            updateAwardType:(awardtype: string)=> set(() => ({awardType: awardtype})),
            getRoute:(pathname:string)=> { 
                const awardType:string= get().awardType;
                const formType: string = get().storeFormType;
                let nextRoute: string="";
                const currentForm = pathname.length>0? pathname.slice(pathname.lastIndexOf("/")+1,pathname.length):"";
                //console.log("awardType => ", awardType, " formtype=> ", formType, " current form=> ", currentForm);
                switch(awardType) {
                    case AwardTypes.PRIME_AWARD:
                        if(currentForm===FormTypes.AWARD_INFO)
                            nextRoute = RouteConstants.PRIME_AWARD_ORGANIZATION
                        else if(currentForm===FormTypes.ORG_INFO)
                            nextRoute = RouteConstants.PRIME_AWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                            nextRoute = RouteConstants.PRIME_AWARD_REVIEW;
                        else nextRoute= RouteConstants.DASHBOARD;
                        break;
                    case AwardTypes.SUB_AWARD:
                        if(currentForm===FormTypes.AWARD_INFO && formType==="organization")
                            nextRoute = RouteConstants.SUBAWARD_ORGANIZATION
                        else if(currentForm===FormTypes.AWARD_INFO && formType==="individual")
                            nextRoute = RouteConstants.SUBAWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.ORG_INFO)
                            nextRoute = RouteConstants.SUBAWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                            nextRoute = RouteConstants.SUBAWARD_REVIEW;
                        else nextRoute= RouteConstants.DASHBOARD;
                        break;
                    case AwardTypes.NON_AWARD:
                        if(currentForm===FormTypes.AWARD_INFO && formType==="organization")
                            nextRoute = RouteConstants.NON_AWARD_ORGANIZATION
                        else if(currentForm===FormTypes.AWARD_INFO && formType==="individual")
                            nextRoute = RouteConstants.NON_AWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.ORG_INFO)
                            nextRoute = RouteConstants.NON_AWARD_KEY_INDIVIDUAL
                        else if(currentForm===FormTypes.INDIVIDUAL_INFO)
                            nextRoute = RouteConstants.NON_AWARD_REVIEW;
                        else nextRoute= RouteConstants.DASHBOARD;
                        break;
                    default:
                        nextRoute = RouteConstants.DASHBOARD;
                }   
                return nextRoute;
            },
        }),
        { name: StoreNames.Nav, getStorage: () => localStorage })
);

