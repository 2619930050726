import axios from "axios";
import IOrganization, { initialiseIOrganization } from "../Interfaces/IOrganization";
import { IIndividual, initializeIIndividual } from "../Interfaces/IIndividual";
import { ISearch } from "../Interfaces/ISearch";
import { ICountry } from "../Interfaces/ICountry";
import { IPicklist } from "../Interfaces/IPicklist";
import { IComment, initializeComment } from "../Interfaces/IComment";
import { IPifSubmission } from "../Interfaces/IPifSubmission";
import {GridKeyIndividual } from "../App.types";
import { UserDetails } from "../components/stores/interfaces/UserDetails";





 const basePath = process.env.REACT_APP_API_URL;
 axios.defaults.withCredentials=true;
 
// const instance = axios.create({ 
//     baseURL: process.env.REACT_APP_API_URL,
//     withCredentials:true
//   });

 

//   instance.interceptors.request.use(config=>{
//     const [cookies] = useCookies(['AuthDetails']);
//     if(cookies?.AuthDetails){
//       config.headers['Cookies'] = `auth_token=${cookies.AuthDetails}`
//     }
//     return config;
//   },error=>{
//     return Promise.reject(error);
//   });

//  //check if jwt token exists and apply it


//  if(cookies.AuthDetails){dddz
//   instance.interceptors.request.use(config=>{
//     let token = Token
//    })
//  }


export const searchOrganizations = async (orgName: string):Promise<ISearch[]>=>{
  console.log("Searching organization", orgName)
  const resultList: ISearch[]=[];
  
  try{
 await  axios.get(basePath+"/organization/search/"+orgName,{withCredentials: true})
      .then(response => {
        if(response.status==200 && response.data.length>0){
        for(const x of response.data) resultList.push(x);
        }
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });
  }
  catch(err){
    console.log("Errors encountered", err)
  }
      return resultList;
}




export const getOrganizations = async (objectId: string):Promise<IOrganization>=>{
    let organizationData:IOrganization = initialiseIOrganization();
    
    try{
         await  axios.get(basePath+"/organization/fetch/"+ objectId)
        .then(response => {
          if(response.status==200 && response.data){
            console.log("respons =>", response.data)
          organizationData = response.data;
        }})
        .catch(error => {
          console.error('Error fetching options:', error);
        });
    }
    catch(err){
      console.log("Errors encountered", err)
    }
        return organizationData;
}


export const searchIndividuals = async (queryParam: string):Promise<ISearch[]>=>{
  var resultList: ISearch[]=[];
  try{
     await axios.get(basePath+"/individual/search/"+ queryParam)
        .then(response => {
          if(response.status==200 && response.data.length>0){
            for(const x of response.data) resultList.push(x);
          }
        })
        .catch(error => {
          console.error('Error fetching options:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
        return resultList;
}

export const getIndividual = async (objectId: string):Promise<IIndividual>=>{
   
    let individualData:IIndividual=initializeIIndividual();
    try{
       await axios.get(basePath+"/individual/fetch/"+ objectId)
          .then(response => {
            if(response.status==200 && response.data){
              individualData =response.data;
              console.log("Individual returned ", individualData)
            }
          })
          .catch(error => {
            console.error('Error fetching options:', error);
          });
        }
      catch(err){
        console.log("Errors encountered", err)
      }
      return individualData;
}

export const getCountries= async ():Promise<ICountry[]>=>{
   
  let countryData:ICountry[]=[];
  try{
    
     await axios.get(basePath+"/country")
        .then(response => {
          if(response.status==200 && response.data){
            countryData = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching country data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return countryData;
}

//Fetch picklist by name 
export const getPicklist= async (name:string):Promise<IPicklist[]>=>{
  let picklistData:IPicklist[]=[];
  try{
    
     await axios.get(basePath+"/picklist/picklistByName/"+name)
        .then(response => {
          if(response.status==200 && response.data){
            picklistData = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching picklist data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return picklistData;
}

export const uploadFile = (file: File, PifId: string, DocumentType: string, DocumentId: string) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  formData.append("files", file);
  formData.append("PifId", PifId);
  formData.append("DocumentType", DocumentType);
  formData.append("DocumentId", DocumentId);

  axios.post(basePath + "/document/upload", formData, { headers })
    .then((response) => {
      // window.confirm("File uploaded successfully" + response);
      console.log("File uploaded successfully", response);
    })
    .catch((error) => {
      // window.confirm("File uploaded failed" + error + formData + basePath);
      console.error("Error uploading file:", error);
    });
    
}

export const uploadCitizenshipFile = (file: File, CitId: string, DocumentType: string, DocumentSubType: string, DocAssocId: string) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  formData.append("file", file);
  formData.append("CitId", CitId);
  formData.append("DocumentType", DocumentType);
  formData.append("DocumentSubType", DocumentSubType);
  formData.append("DocAssocId",DocAssocId)

  axios.post(basePath + "/documents/citUpload", formData, { headers })
    .then((response) => {
      // window.confirm("File uploaded successfully" + response);
      console.log("File uploaded successfully", response);
    })
    .catch((error) => {
      // window.confirm("File uploaded failed" + error + formData + basePath);
      console.error("Error uploading file:", error);
    });
    
}

export const createOrUpdateKeyIndvidual = (keyInd: IIndividual, file:Array<any>) => {
  const URL=basePath + "/individual/createOrUpdate";
  console.log(URL);
  const headers = {
    "Content-Type": "application/json",
  };

  console.log(keyInd);
  
//  const emptyAddress={
//    "street": "4 American Legion",
//    "country": "United States",
//    "countryCode": ["0343"],
//   "state": "Virginia",
 //   "city": "Woodbridge",
//    "zipCode": "22191",
//    "phoneNumber": [
//      "7778393690",
//      "7778393690",
//      "7778393490"
 //   ],
//    "faxNumber": [
 //    "7778393690",
 //     "7778393690",
 //     "7778393490"
  //  ],
   // "email": [
  //    "progress@opexusNinga.com"
  //  ]
  //};
 
  //keyInd.address=emptyAddress

  console.log(JSON.stringify(keyInd));
  alert("Starting update");

  axios.post(URL, JSON.stringify(keyInd),{ headers })
  .then(response => {
    //alert("in RESPONSE");
    console.log("data" + JSON.stringify(response.data))
    console.log("cit" + JSON.stringify(response.data.citizenship));
    console.log("cit1" + JSON.stringify(response.data.citizenship[0].individualId));
    const responseCit = response.data.citizenships;
    //createOrUpdateCitFiles(responseCit,file)
  })
  .catch(error => {
    console.error('Error fetching options:', error);
    alert('Error fetching options:' + error);
  });

  
}

const createOrUpdateCitFiles = (respCitFile:Array<any>, files:Array<any>) =>{

  console.log("insde CreateOrUpdateCitFiles")
  respCitFile.forEach((element) => {
    console.log("update one")
    uploadCitizenshipFile(files[0],element.individualId,"doctye","subdoctype","docAssocId");
  })

  alert("finished updating");

}

export const postComments= async (comment: IComment, pifId: string):Promise<IComment>=>{
  console.log("Received comment for post", comment)
  let result: IComment=initializeComment();
  
  try{
    await  axios.post(basePath+"/subaward/comments/"+pifId,comment)
          .then(response => {
            if(response.status===201 && response.data.length>0){
               result={...response.data};
               console.log("Returned data is ",response.data);
          }})
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    console.log("Errors encountered", err)
  }
      return result;
}

export const getComments= async (pifId: string):Promise<IComment[]>=>{
  const result: IComment[]=[];
  
  try{
    await  axios.get(basePath+"/subaward/comments/"+pifId)
          .then(response => {
            if(response.status===200 && response.data.length>0){
            for(const x of response.data) result.push(x);
            }
          })
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    console.log("Errors encountered", err)
  }
      return result;
}

export const updateComments =  async (input:string[])=>{
  console.log("Set comment IDs to read", input)

  try{
    await  axios.post(basePath+"/subaward/viewedComments",input)
          .then(response => {
            if(response.status===201 && response.data.length>0){
               
               console.log("Result of updating comment is ",response.data);
          }})
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    console.log("Errors encountered", err)
  }
}


export const postSubAward= async (submission: IPifSubmission):Promise<Boolean>=>{
  console.log("Received comment for post", submission)
  let result: Boolean = true;
  
  try{
    await  axios.post(basePath+"/subaward/submitpif",submission)
          .then(response => {
            if(response.status===201 ){
               result={...response.data};
               console.log("Returned data is ",response.data);
          }})
          .catch(error => {
            console.error('Error fetching options:', error);
          });
  }
  catch(err){
    result=false;
    console.log("Errors encountered", err)
  }
      return result;
}
export const getIndividualsByPifId= async (pifId:string):Promise<IIndividual[]>=>{
  let ind:IIndividual[]=[];
  try{
    
    await axios.get(basePath+"/individuals/"+pifId)
        .then(response => {
          if(response.status==200 && response.data){
            ind = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching individual data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    console.log(ind);
    return ind;
}

export const keyIndividualsToGridKeyIndividual=(keyIndv:IIndividual[]):GridKeyIndividual[]=>{

  let resultList:GridKeyIndividual[]=[];

  keyIndv.forEach(ind =>
    {
      let gridInd:GridKeyIndividual={
        cellId:"",
        id: "",
        name:"",
        role: "",
        typeId: "",
      }
        
      gridInd.cellId=ind?.indId;
      if(ind?.citizenship && ind?.citizenship.length>0) {
        gridInd.id=ind?.citizenship[0]?.governmentIssuedPhotoId ||"";
        gridInd.typeId=ind?.citizenship[0]?.governmentIdentificationT||"";
      }
      gridInd.name=(ind?.firstName+ " " + ind?.middleName + " " + ind?.lastName);
      gridInd.role=ind?.organizationTitleOrRank;
      resultList.push(gridInd);
    }
    
  ) //End ForEach
    
    return resultList;
}

export const getOrganizationByPifId= async (pifId:string):Promise<IOrganization>=>{
  let org:IOrganization=initialiseIOrganization();

  try{
    await axios.get(basePath+"/organization/"+pifId)
        .then(response => {
          if(response.status==200 && response.data){
            org = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching individual data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return org;
}

export const getOrganizationFilesByPifId= async (pifId:string):Promise<any[]>=>{
 /*
  let files:IorganizationUpload =[]
  try{
    await axios.get(basePath+"/getOrgDocs/"+pifId)
        .then(response => {
          if(response.status==200 && response.data){
            files = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching individual data:', error);
        });
      }
    catch(err){
      console.log("Errors encountered", err)
    }
    return files;
    */

    return []
}

export const createOrUpdateOrg= (org: IOrganization, file:Array<any>) => {
  const URL=basePath + "/organization/createOrUpdate";
  console.log("Organization being passed ",org);
  const headers = {
    "Content-Type": "application/json",
  };

  console.log(file);
  console.log(org);
  console.log(JSON.stringify(org));


  axios.post(URL, JSON.stringify(org),{ headers })
  .then(response => {
    if(response.status==200 && response.data){
      console.log("success");
    }
  })
  .catch(error => {
    console.error('Error fetching options:', error);
    alert('Error fetching options:' + error);
  });

}

export const getLoginUrl = async ():Promise<string>=>{
  return await axios.get(basePath + "/loginUrl")
    .then(response => {
      if (response.status == 200 && response.data) {
        return response.data;
      }
    })
    .catch(error => {
      console.error('Error fetching options:', error);
      alert('Login Not Available');
    });

}
  

export const validateToken = async (code:string): Promise<UserDetails> => {
  let userDetail:UserDetails = {
    firstName: "",
    lastName: "",
    email: "",
    role:"",
    tokens:{},
  }

  const URL= basePath + "/authenticate";


  await axios.post(URL+"/"+code,{},{withCredentials: true})
  .then(response => {
    if(response.status===200 && response.data){
      userDetail = response.data;
    }
  })
  .catch(error => {
    console.error('Error fetching options:', error);
    alert('Error fetching options:' + error);
    throw error;
  });

  return userDetail;
}


export const createPif = async(data:any):Promise<string> =>{ 
  const URL= basePath + "/subaward/createupdate/";

  return await axios.put( URL, data)
    .then(response => {

      alert(response.status + " : created");
      // setting new pifID
      return response.data.pifId;
      // console.log("In axios put - New pif ID: " + newPIFID);
    })

    .catch(error => {
      return null;
      //prompt("error",JSON.stringify(URL));
      //prompt("error",JSON.stringify(error.request));
      console.error('Error fetching options:', error);
    });
}