import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SubmissionComments({ setComment,comment,disabled}:{setComment:(input:string)=>void, comment: string,disabled: boolean}) {
  
  //const [value, setValue] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const maxChars = parseInt('1024');
  
  let navigate = useNavigate();
  const useAutosizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string
  ) => {
    useEffect(() => {
      if (textAreaRef) {
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaRef.style.height = "75px";
        const scrollHeight = textAreaRef.scrollHeight + 2;
  
        // We then set the height directly, outside of the render loop
        // Trying to set this with state or a ref will product an incorrect value.
        textAreaRef.style.height = scrollHeight + "px";
      }
    }, [textAreaRef, value]);
  };


 
  
  useAutosizeTextArea(textAreaRef.current, comment);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setComment(val);
   // setValue(val);
  };

  return (
    <>
      <div className="text-start titleSection textFormat">Submission Comments</div>
      <div className="rounded-2 contentSection textFormat">
          <span>
            Enter any comments that will assist in the processing of this
            request.
          </span>
        <div className="mx-auto text-start">
          <textarea
            style={{ width: "90%", height: "75px" }}
            id="submission-text"
            onChange={handleChange}
            ref={textAreaRef}
            rows={1}
            value={comment}
            disabled={disabled}
            maxLength={maxChars}
          />
        </div>
      </div>
    </>
  );
}
