import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


import {  InputLabel, MenuItem, Select,  Stack,  Typography, Modal } from "@mui/material";
import { ReactComponent as AttachmentIcon } from '../../../assets/images/svg/attachmentAdd.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/svg/closeIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/svg/delete.svg';
import { SvgIcon } from "@mui/material";



interface UploadProps {
  uploadType: string;
  uploadTitle: string;
  uploadOptions: string[];
  uploadDesc: string;
  open: boolean;
  placeHolderText: string;
  handleClickBtnClose: () => void;
  handleClickBtnOkay: (selecetedOption: string, selectedFile: File) => void;
}


const grayColor = "#c3c3c3";
const usaidBlue = '#0164B9'
const fontSize = 12;
const style = {

};

const inputStyle = {
  minWidth: 50,
  color: '#808080',

}



const textStyle = {
  color: "black",
  fontSize: 12,
}



export default function FileUploadModal({ uploadDesc, uploadType, uploadTitle, uploadOptions, open, handleClickBtnClose, handleClickBtnOkay, placeHolderText }: UploadProps) {
  const maxFileSize = 6*1024*1024; //6MB
  const [selectedOption, setSelectedOption] = useState(placeHolderText);
  const [selectedFile, setSelectedFile] = useState<File|null>(null);
  //const [file, setFile] = useState({ selectedFile: null });

  const [message, setMessage] = useState<{errors:string[]}>({errors:[]});
  const zIndex = 2000;
  const handleDropdownChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  const supportFormats: string[]=["jpg","jpeg","jpg","pdf","png"];

  const clearComponent =()=>{
    setMessage({errors:[]});
    setSelectedFile(null);
    setSelectedOption("");
  }

  const clearComponentPartial =()=>{
    setMessage({errors:[]});
    setSelectedFile(null);
  }

  // useEffect(()=>{
  //   clearComponent();
  // },[]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearComponentPartial();

    const errors =[];
    if(event.target.files && event.target.files[0]){
      const file = event.target.files[0];
      //@ts-ignore
      const extension = event.target.files[0].name.match(/\.([^.]+)$/)[1];

      if(!supportFormats.includes(extension)){
        errors.push(extension +" is not supported. Please upload one of the following file types: "+ supportFormats.join(", ") );
      }
     

      if(file && file.size > maxFileSize){
        let size = file.size/1024/1024;
         errors.push("The file size "+size.toFixed(0)+" MB exceeds the maximum file size of "+ maxFileSize/1024/1024 + " MB");
      }

      console.log("Length of message ", JSON.stringify(message));
     
      if (errors.length===0){
          setSelectedFile(file);
      }
      else{
        setMessage({errors:errors});
      }
  }}

  const handleUpload = () => {
    if (selectedFile) {
      handleClickBtnOkay(selectedOption, selectedFile);
      // setSelectedOption("");
      // setSelectedFile(null);
      clearComponent();
    }
  }

  return (
    <Modal open={open} closeAfterTransition onClose={handleClickBtnClose} sx={{ backgroundColor: 'rgba(0,0,0,0.25)', zIndex: zIndex, border: "0.5px solid black" }}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,0)",
          width: 410,
          minWidth: 360,
          bgcolor: "background.paper",
          border: "0.5px solid black",
          borderRadius: '8px',
          boxShadow: "0 4px 12px  'rgb(0 0 0 / 0.2)'",
          p: 1, zIndex: zIndex + 1
        }}
      >
        <Box display="flex" alignItems="center" width="100%">
          <Box flexGrow={1} display="flex" justifyContent="center">
            <Typography sx={{ color: 'black', fontSize: fontSize, fontWeight: 'bold', textTransform: 'capitalize' }}>{uploadTitle}</Typography>
          </Box>
          <Box justifyContent={'flex-end'} onClick={handleClickBtnClose}> <SvgIcon>
            <CloseIcon onClick={handleClickBtnClose} title={"Close"} />
          </SvgIcon>
          </Box>
        </Box>
        {message.errors.length>0 && <Stack direction={'column'} justifyContent={'center'} sx={{margin:3}} >
          {message.errors.map((m)=>(
          <Box  justifyContent="center" width="100%">
            <Typography sx={{ color: 'red', fontSize: fontSize, fontWeight: 'bold' }}>{m}</Typography>
          </Box>
        ))}
      </Stack>}
        {/* <DialogContentText>{props.content}</DialogContentText> */}
        <Stack direction={'row'} spacing={3} justifyContent={'center'}>
          <Box  >
            <InputLabel sx={{ p: 0 }} id={uploadType}>
              <Typography sx={textStyle}><span style={{ color: 'red' }}> * </span>{uploadDesc}</Typography>
            </InputLabel>
            <Select
              MenuProps={{
                style: {
                  zIndex: zIndex + 2,
                  border: '1px solid black'
                }
              }}
              sx={{
                minWidth: 180,
                width: 215,
                height: 32,
                bgcolor: grayColor,
                '& .MuiSelect-select': {
                  paddingRight: 2,
                  paddingLeft: 2,
                  paddingTop: 1,
                  paddingBottom: 1,
                  border: 'none'
                },
                '& .MuiOutlinedInput-notchedOutline': { border: 'none', borderBottom: '1px solid black' },
                '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none', borderBottom: '1px solid black' },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none', borderBottom: '1px solid black' },
              }}
              labelId="select-document-upload"
              label={placeHolderText}
              placeholder={placeHolderText}
              value={selectedOption}
              onChange={(e) => handleDropdownChange(e.target.value)}
            >
              {uploadOptions.map((val, i) => {
                return <MenuItem key={i} value={val}><Typography sx={{ color: 'black', fontSize: fontSize, textTransform: 'capitalize' }}>{val}</Typography></MenuItem>
              })}
            </Select>
          </Box>
          {/* <FormControl variant="standard" sx={{m:1,p:2, minWidth:120}}> */}
          <Box >
            <InputLabel sx={{ p: 0 }} id={uploadType}><Typography sx={{ color: 'black', fontSize: fontSize, textTransform: 'capitalize' }}>File to Upload:</Typography></InputLabel>
            <Button
              variant="contained"
              component="label"
              startIcon={<SvgIcon><AttachmentIcon style={{ color: 'black', fontSize: "large" }} /></SvgIcon>}
              sx={{
                bgcolor: grayColor
              }}>
              <Typography sx={{ color: 'black', fontSize: fontSize, textTransform: 'capitalize' }}>Choose File
                <input type="file" hidden onChange={handleFileChange} />
              </Typography>
            </Button>
            {selectedFile && <Typography sx={{...textStyle, pt:1}}>{selectedFile?.name} <DeleteIcon style={{transform:'scale(0.8)'}} onClick={()=>clearComponentPartial()}/></Typography> }
          </Box>
        </Stack>

        <Stack direction={'row'} spacing={2} justifyContent={'center'} marginTop={2}>
          {/* <input type="file" /> */}
          <Button onClick={()=>{
            clearComponent();
            handleClickBtnClose();
          }} sx={{
            bgcolor: grayColor,
            '&:hover': {
              backgroundColor: usaidBlue,
              color: 'white'
            }
          }}><Typography
            sx={{
              color: 'white',
              fontSize: fontSize,
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: usaidBlue,
                color: 'white'
              }
            }}
          >Cancel</Typography></Button>
          <Button onClick={handleUpload} disabled={selectedFile===null && selectedOption!==''}
            sx={{
              bgcolor: usaidBlue,
              '&:hover': {
                backgroundColor: usaidBlue,
                color: 'white'
              },
              '&:disabled': {
                backgroundColor: grayColor,
                color: 'white'
              }
            }}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: fontSize,
                textTransform: 'capitalize',
              }}>Upload
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

