import "bootstrap/dist/css/bootstrap.min.css";
import PreviewPIF from "./PreviewPIF";
import SubmissionComments from "./SubmissionComments";
import CertificationParent from "./CertificationParent";
import { useState, useRef} from "react";
import "./ReviewAndSubmit.css";

import { postSubAward } from "../../../Services/Api";
import { IPifSubmission } from "../../../Interfaces/IPifSubmission";
import { useAwardeeStore } from "../../stores/AwardeeStore";
import { format } from 'date-fns';

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import { authStore } from "../../stores/AuthStore";
import { useFormProps } from "../../stores/FormProperties";

import {
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { common, grey, blue} from "@mui/material/colors";

axios.defaults.withCredentials=true;

export default function ReviewAndSubmit() {
  const { updateNavStore} = useNavStore();
  const { disableFormFields, storedPifId } = useFormProps();
  const { details } = authStore();



  const certLabel = "I certify in submitting this form that I have taken reasonable steps (in accordance with sound business practices) to verify the information contained in this form. I understand that the U.S. Government may rely on the accuracy of such information in processing this vetting request.";

  const boldVettingText = 'FOR DIRECT VETTING ONLY:  ';

  const vettingLabel = "If this award is part of the Direct Vetting Test, then in lieu of the certification, I confirm that I have carefully reviewed the information submitted by the subawardee and such information is accurate and complete to the best of my knowledge and belief. I understand that the U.S. Government may rely on the accuracy of such information in processing this vetting request.";

  const certText = "Contractor/Grantee/Recipient certifies in submitting this form that it has taken reasonable steps (in accordance with sound business practices) to verify the information contained in this form. Contractor/Grantee/Recipient understands that the U.S. Government may rely on the accuracy of such information in processing this vetting request.";

  const checkboxRef = useRef(null);
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);

  // Customizing Colors
  // const shadeofGray = grey[300];
  // const usaidBlue = blue[800];

  const handleCancel = () => {
    setIsChecked(false);
    setComment("");
    navigate(RouteConstants.DASHBOARD);
  }

  const handleDraft = () => {
    // TESTING
    console.log("Reached handleDraft");
    // navigate(RouteConstants.DASHBOARD);
  }
  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = async () => {
    try{

      const savePIFPartTwoURL: string = process.env.REACT_APP_API_URL + "/pif/savePIFPageTwo"; 
      
          const response = await axios.post<string>(savePIFPartTwoURL, storedPifId , {
             headers: {
                   'Content-Type': 'application/json'
           },
            });

        alert(response.data);
        // submitSubAward
        navigate(RouteConstants.SUCCESS);
      }
        
        catch(error){
          console.error('Error during PDF download', error)
      }



    //move to below after demo

    if (!details) {
      console.log("details not found in store");
        return;
    }
    if (isChecked) {
      return await submitSubAward();
    }
    else {
      console.log("Checkbox not checked");
      alert("Please read statement and mark checkbox")
    }
  }


  const handleChange = () => {
    setIsChecked(!isChecked);
  }

  const clearData = () => {
    setIsChecked(false);
  }

  const submitSubAward = async () => {

    const submission: IPifSubmission = {
      comment: comment,
      pifId: storedPifId,
      userName: useAwardeeStore.getState().userName,
      dateOfSubmission: new Date()
    }
    const result = await postSubAward(submission);
    if (result) navigate(RouteConstants.SUCCESS); // Redirect to success page

    else alert("submission failed")

  }



  // get Name and title/Org from global state - once we get it from the user table 
  // TODO:  In a Future story we wiil get the user infor from the user table
  let name = '';
  let orgTitle = useAwardeeStore.getState().awardee;
  // TESTING
  console.log("Name from useAwardeeStore: " + name);
  if (orgTitle === '' || orgTitle === undefined || orgTitle === '') {
    orgTitle = 'Unknown Company';
  }


  if (name === '' || name === undefined || name === '') {
    name = 'Username';
  }



  // convert date to correct format
  const currDate: Date = new Date();
  const formattedDate = format(currDate, 'dd-MMM-yyyy');

  // Make User confirm before Submitting Form
  const handleConfirmation: any = () => {
    // Perform action upon confirmation
    console.log('Confirmed!');
    // close window - set open to false
    setOpen(false);
    handleSubmit();
  };

  // Dialog variables
  const message = "Did you validate all the entered info for accuracy?";
  const title = "Review and Submit";

  // just using for testing demo july 31 2024
  // function  toggleBtn ()  {
  //   setIsSubmitButtonDisabled(false);
  // }


  return (
    <div className="w-80 container-fluid">
      {storedPifId && <p className='text-start pif-id'><strong>PIF ID:</strong> {storedPifId}</p>}

      <div className="rounded-3 fs-6">
        <PreviewPIF pifid={storedPifId} />
      </div>
    
       {/* <div>
        <DocumentsViewer
          pifid={"PIF-001-1"}
        />
      </div> */}
     
      <div className="rounded-3">
        <SubmissionComments setComment={setComment} comment={comment} disabled={disableFormFields}/>
      </div>
      <CertificationParent>
        <div className="text-start titleSection textFormat">Certification </div>
        <div className="rounded-3 contentSection textFormat">
          <div className="row">
            <p>{certText} </p>
            <br></br><br></br><b></b><br></br>
            <p> <strong>Please read the statement below and place a checkmark in the checkbox in lieu of a signature: </strong></p>
            <br></br><br></br>
          </div>  {/*new*/}
          <div className="row">
            <div className="col-1 text-end">
              <input
                ref={checkboxRef}
                id="agree-checkbox"
                type="checkbox"
                className="agree-checkbox"
                checked={isChecked}
                onChange={handleChange}
                disabled={disableFormFields}
                required
              />
            </div>
            <div className="text-start col-9">
              <span>{certLabel}</span>
            </div>

          </div>
          <br></br>
          <p> <strong>{boldVettingText}</strong>{vettingLabel}</p>
          <br></br><br></br>
          <div className="m-0 p-0">
            {/* TODO: the Name, and title field should be dynamic like the Date field  */}
            <dl>
              <dt>Name</dt>
              <dd>{details?.firstName + " " + details?.lastName}</dd>
              <dt>Title/Organization</dt>
              <dd> {orgTitle}</dd>
              <dt>Date</dt>
              <dd>{formattedDate}</dd>
            </dl>
          </div>
        </div>
        {/* </div> */}
      </CertificationParent>

      {/* {open && ( */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id='dialog-title'>
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
            color:common.black,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="dialog-description">
            <Typography color="black" variant="h6">{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)} style={{backgroundColor:"gray"}} > No 
              </Button>
          <Button variant="contained" onClick={handleConfirmation} autoFocus style={{backgroundColor:"#0164B9"}}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* // )} */}

      <div className="d-flex justify-content-center gap-4 mt-3 mb-5">
        <button type="submit" className="btn loginButton usaid-blue" onClick={handleCancel}>Cancel</button>
        {/* <button type="button" className="btn" onClick={handleDraft}>Save Draft</button> */}
        {/* setOpen(true) - sets open state to true, opens Confirm Dialog, then goes to handleConfirmation  */}
        <button type="button" className="btn" onClick={() => setOpen(true)}>Save Draft</button>
        {/* <button type="submit" className="btn loginButton usaid-blue" disabled={isSubmitButtonDisabled} onClick={handleConfirmation}>Submit</button> */}
        {/* <button type="submit" className="btn loginButton usaid-blue" disabled={isSubmitButtonDisabled} onClick={() => setOpen(true)}>Submit</button> */}
        {/* Removing disabled for demo: July 31 2024 */}
        <button type="submit" className="btn loginButton usaid-blue" disabled={!isChecked || details?.role === 'Data Entry'} onClick={() => setOpen(true)}>Submit</button>

      </div>
    </div>
  );
}












// Other Way to Form the Non Award JSON:
// const certification = { authorizingOfficialsFullName: "Michael Niclous Smith", titleOrganization: "Security Officier United States Office of International Development", signatureOfAuthorizaingOfficial: "Signature", date: "2023-01-15" }; 
//     const data = { certification, objectName: "INDI-1002", dateOfBirth: "1980-05-15", birthState: "California", countryOfBirth: "USA",
//        country: "United States", idCountryOfIssuance: "USA", govIssuePhotoIdType: "Driver's License", completeGovIssuePhotoIDNumber: "DL123456789", 
//        lawfulPermanentResidentCardNumber: "A123456789", organizationTitleOrRank: "Manager", firstName: "John", lastName: "Doe", 
//        middleName: "Michael", nameOfIndividual: "John M. Doe", missionName: "Project X", licensesAndCertifications: "Certified ScrumMaster", 
//        associatedOrgId: "ORG-123", legacyId: "LID-789", awardeeId: "AWD-456", birthDistrict: "Los Angeles", birthCity: "Los Angeles", gender: "Male", occupation: "Software Engineer", tribalAffiliation: "Navajo Nation", source: "Web Form", citizenship: [ { countryOfCitizenship: "USA", governmentIdentificationT: "Passport" } ], address: [ { street: "123 Main St", city: "Anytown", state: "CA", postalCode: "12345", country: "United States" } ], district: "District 1", villageCity: "Anytown", stateRegion: "California", usCitizen: "No", permResident: "No", organizationName: "ABC Company", phone: "+1-555-123-4567", alternatePhone: "+1-555-987-6543", email: "john.doe@example.com", alternateEmail: "johndoe.alt@example.com", isCellPhone: false, isAlternatePhoneCellPhone: true };  