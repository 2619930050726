
import './Tiles.css'
import "bootstrap/dist/css/bootstrap.min.css";


export default function Tiles(props: {
        title: string;
        content: string[];
        subHeadings?: boolean;
      }) {
        const mergedTextWithParagraphs = (items: string[]) => {
          return items.map((item, idx) => {
            if(props.subHeadings){
                let portions = item.split(':');
            return (<span key={idx}>
              <p><b>{portions[0]}:</b>{portions[1]}</p>
            </span>)}
            else{
              return( <span key={idx}>
                <p>{item}</p>
              </span>  
              )    
            }
        });
        };
      
        return (
          <>
            <div className="text-start titleSection textFormat">{props.title}</div>
      
            <div className="contentSection textFormat">
              {mergedTextWithParagraphs(props.content)}
            </div>
          </>
        );
      }

