import "bootstrap/dist/css/bootstrap.min.css";
import "../../common/Tiles/Tiles.css";
import AdditionalEmail from "./AdditionalEmail";
import AdditionalPhoneNumber from "./AdditionalPhoneNumber";
import "./OrganizationInfo.css";
import OrganizationSearch from "./OrganizationSearch";
import ParentCompanyName from "./ParentCompanyName";
import {
  IaddressControlAttributes,
  IcontrolAttributes,
  IorganizationUpload,
  IcontrolParentCompanyAttribs,
} from "../../../App.types";
import IOrganization, { initialiseIOrganization } from "../../../Interfaces/IOrganization";
import { useState, useEffect, SyntheticEvent, useRef } from "react";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme
import { ColDef } from "ag-grid-community";
import DeleteCellRenderer from "./DeleteCellRenderer";
import React from "react";
import ViewCellRenderer from "./ViewCellRenderer";
import icon_upload_file from "../../../assets/images/svg/file-upload-earmark.svg";
import {getOrganizations, getPicklist, uploadFile, getOrganizationByPifId, createOrUpdateOrg, getOrganizationFilesByPifId } from "../../../Services/Api";
import { ISearch } from "../../../Interfaces/ISearch";
import AdditionalFaxNumber from "./AdditionalFaxNumber";
import HelpDialog from "../../common/HelpDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import FileUploadModal from "../../common/modals/FileUploadModal";
import SingleAddressField from "./SingleAddressField";
import { useFormProps } from "../../stores/FormProperties";
import { useCountryStore } from "../../stores/CountryStore";
import { useOrgTypeStore } from "../../stores/OrgTypes";





export default function OrganizationInfo() {
  const { updateNavStore, getRoute } = useNavStore();
  const { disableFormFields, storedMission, storedBureau, storedPifId } = useFormProps();
  const { state, pathname } = useLocation();
  let inputPifId = "";
  let formType = "";

  const [pifId,setPifId] = useState<string>("");

  var [orgId, setOrgId] = useState("");
  var [orgFileNames, setOrgFileNames] = useState<String[]>([]);
  //var [organizationData,setOrganizationData] = useState<IOrganization>();

  const [open, setOpen] = React.useState(false);
  // const [orgTypes, setOrgTypes] = useState<IPicklist[]>([]);

  //const [organization,setOrganization] = useState<IOrganization>();

  const [parentCompanyField, showParentCompanyField] = useState(false);
  
  const {countries,updateCountries} = useCountryStore();
  const {storedOrgTypes, updateOrgTypes} = useOrgTypeStore();

  useEffect(()=>{
    if (countries.length===0) updateCountries();
    if(storedOrgTypes.length===0) updateOrgTypes();
  },[])




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickBtnClose = () => {
    setOpen(false);
  };

  const handleClickBtnOkay = (selectedOption: any, selectedFile: File) => {
    var upload: IorganizationUpload = {
      //TODO: need to update with better approach for individual ID
      cellId: Math.floor(Math.random() * 100),
      name: selectedFile.name,
      type: selectedOption,
      file: selectedFile,
      onServer: false
    };

    //TODO: add logic to add to database then wrap below logic within it
    setRowData((u) => [...u, upload]);
    setOpen(false);
  };


  const handleClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  var [Subawardee, setSubawardee] = useState("");
  var [organizationType, setOrganizationType] = useState("");
  var [parentCompanyNameOne, setParentCompanyNameOne] = useState("");
  var [parentCompanyNameTwo, setParentCompanyNameTwo] = useState("");
  var [showSecondName, setShowSecondName] = useState(false);
  var [usOrganization, setUsOrganization] = useState("");
  var [primaryCountry, setPrimaryCountry] = useState("");
  var [primaryResidence, setPrimaryResidence] = useState("");
  var [primaryCity, setPrimaryCity] = useState("");
  var [primaryState_region, setPrimaryState_region] = useState("");
  var [primaryZipCode, setPrimaryZipCode] = useState("");
  var [secondaryCountry, setSecondaryCountry] = useState("");
  var [secondaryResidence, setSecondaryResidence] = useState("");
  var [secondaryCity, setSecondaryCity] = useState("");
  var [secondaryState_region, setSecondaryState_region] = useState("");
  var [secondaryZipCode, setSecondaryZipCode] = useState("");

  // Using these to set state  in place of the (deprecated) multiple address fields - input will be just one field
  const [orgAddress, setOrgAddress] = useState("");
  const [altAddress, setAltOrgAddress] = useState("");
  const [displayAltOrgAddress, setDisplayAltOrgAddress] = useState(false);

  var [phoneOne, setPhoneOne] = useState("");
  var [phoneTwo, setPhoneTwo] = useState("");
  var [countryCodeOne, setCountryCodeOne] = useState("");
  var [countryCodeTwo, setCountryCodeTwo] = useState("");
  var [faxNumber, setFaxNumber] = useState("");
  var [faxTwo, setFaxTwoNumber] = useState("");
  var [emailOne, setEmailOne] = useState("");
  var [emailTwo, setEmailTwo] = useState("");
  const [website, setWebsite] = useState("");

  const [formValidated, setFormValidated] = useState(false);


  const [inputSubAwardee, setInputSubAwardee] = useState('');
  const [inputparentCompanyNameOne, setInputParentCompanyNameOne] = useState('');
  const [inputUsOrganization, setInputUsOrganization] = useState('');
  const [inputTypeOfOrganization, setInputTypeOfOrganization] = useState('');
  const [inputPrimaryCountry, setInputPrimaryCountry] = useState('');
  const [inputPrimaryResidence, setInputPrimaryResidence] = useState('');
  const [inputPrimaryCity, setInputPrimaryCity] = useState('');
  const [inputPrimaryState_region, setInputPrimaryState_region] = useState('');
  const [inputPrimaryZipCode, setInputPrimaryZipCode] = useState('');
  const [inputPhoneOne, setInputPhoneOne] = useState('');
  const [inputEmailOne, setInputEmailOne] = useState('');
  const [inputWebsite, setInputWebsite] = useState('');
  const [inputFaxNumber, setInputFaxNumber] = useState('');
  const [inputOrgAddres, setInputOrgAddres] = useState('');



  // Control Awardee Type based type of award:  Primeaward, Subaward, Non-Award
  // Values will be Primeawardee, Subawardee, and Non-Awardee
  const [awardeeType, setAwardeeType] = useState<string>('');

  //*Get location from url
  const urlLolcation = useLocation();
  function getCurrentUrl() {
    return urlLolcation.pathname as string
  }

  useEffect(() => {
  
    /* Detect WHICH TYPE OF AWARD will likely change in the future 
       below is already defined in the store
    */
    if (getCurrentUrl().includes('subaward')) {
      setAwardeeType("Subawardee");
    }
    if (getCurrentUrl().includes('primeaward')) {
      setAwardeeType("Prime Awardee");
    }
    if (getCurrentUrl().includes('nonaward')) {
      setAwardeeType("Non-Awardee");
    }

  }, [awardeeType]);


  useEffect(()=>{
    const populateOrg = async () => {
      let response: IOrganization = await getOrganizationByPifId(storedPifId);//hardcode pifId
      if (response !== undefined && response.pifId) {
        //setOrganizationData(response);
        setOrgValues(response);
      }
    }

    inputPifId = state && state.pifId ? state.pifId : ""
    formType = state && state.formType ? state.formType : ""

    setPifId(inputPifId);
    populateOrg();
  },[storedPifId])

  const populateOrgFiles = async (pifId: string) => {
    let response: String[] = await getOrganizationFilesByPifId(storedPifId);//hardcode pifId

    if (response !== undefined) {
      //setOrganizationData(response);
      setOrgFileNames(response);
      console.log(response)
    }
  }

  const setOrgValues = (org: IOrganization) => {
    console.log("response from setting ", JSON.stringify(org))
    //pifId.current = org.pifId;
    setOrgId(org.orgId);
    setSubawardee(org.organizationName) //
    setOrganizationType(org.organizationType);
    setWebsite(org.website)
    setUsOrganization(org.isUsOrganization?"yes":"no"||"")
    //if (org.organizationType === "Branch" || org.organizationType === 'Subsidiary') {
    setOrganizationType(org.organizationType);
    setSubawardee(org.organizationName);
    showParentCompanyField(org?.parentCompanyName&&org.parentCompanyName.length>0);
    setParentCompanyNameOne(org.parentCompanyName?org.parentCompanyName[0]:"")
    setWebsite(org.website||"")

    //}

    // else {
    //   setUsOrganization(org.isUsOrganization?"yes":"no")
    // }
   
    if (org?.parentCompanyName && org.parentCompanyName.length > 1) {
      console.log("in here ")
      setShowSecondName(true)
      setParentCompanyNameTwo(org.parentCompanyName[1])
      console.log("in here ", showSecondName, "=> ", parentCompanyNameTwo)
    }

    // if (org.isUsOrganization !== undefined) {
    //   if (org.isUsOrganization === true) {
    //     setUsOrganization(org.isUsOrganization?"yes":"no")
    //   }
    //   else {
    //     setUsOrganization(org.isUsOrganization?"yes":"no")
    //   }
    // }
    setPhoneOne(org.primaryPhoneNumber)
    setPhoneTwo(org.alternativePhoneNumber)
    setEmailOne(org.primaryEmail)
    setEmailTwo(org.alternativeEmail)
    setCountryCodeOne(org.primaryCountryCode)
    setCountryCodeTwo(org.alternativeCountryCode)
    setOrgAddress(org.primaryAddress)
    
    if (org.alternateAddress) {
      setDisplayAltOrgAddress(true)
      setAltOrgAddress(org.alternateAddress||"")
    }

  }



  const [buttonClicked, setButtonClicked] = useState(false);


  var [rowData, setRowData] = useState<IorganizationUpload[]>([]);
  var [colDefs, setColDefs] = useState<ColDef<IorganizationUpload>[]>([
    { field: "name", headerName: "Document File Name", minWidth: 300 },
    { field: "type", headerName: "Document Type", minWidth: 200 },
    {
      headerName: "View",
      width: 100,
      cellRenderer: ViewCellRenderer,
      cellRendererParams: {
        setDataRow: handleGridUploadView,
      },
    },
    {
      headerName: "Delete",
      width: 100,
      cellRenderer: DeleteCellRenderer,
      cellRendererParams: {
        setDataRow: handleGridUploadDelete,
      },
    },
  ]);

  function handleSearchOnChange(event: SyntheticEvent<Element, Event>, value: ISearch): any {
    if (value === null) return;

    let _res: IOrganization = initialiseIOrganization();

    const promise: Promise<IOrganization> = new Promise((resolve, reject) => {
      resolve(getOrganizations(value.objectId));
      reject("error occured")
    })

    promise.then(res => {
      //setOrganizationValues(res);
    }).catch((error) => {
      console.log("Error encountered ", error);
    })
  }

  function handleGridUploadDelete(id: number) {
    if (window.confirm("Are you sure you want to delete?") === true) {
      setRowData((uploads) => uploads.filter((u) => u.cellId !== id));
      //TODO: add logic to delete from database
    }
  }

  function handleGridUploadView(id: number) {
    setRowData((prevData) => {
      const fileData = prevData.find((upload) => upload.cellId === id);
      if (fileData && fileData.file) {
        const blob = new Blob([fileData.file], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      } else {
        console.error("File not found.");
      }
      return prevData;
    });
  }

  /*
  function setOrganizationValues(org: IOrganization) {
    setOrganizationType(checkNullValue(org.organizationName));
    setUsOrganization(checkNullValue(org.isUsOrganization));

    if (checkNullObject(org.addresses) && org.addresses.length) {
      setPrimaryCountry(checkNullValue(org.addresses[0].country));
      setPrimaryResidence(checkNullValue(org.addresses[0].addressLine2));
      setPrimaryCity(checkNullValue(org.addresses[0].city));
      setPrimaryState_region(checkNullValue(org.addresses[0].state_region));
      setPrimaryZipCode(checkNullValue(org.addresses[0].zipCode));
      setPhoneOne(checkNullValue(org.addresses[0].phoneNumber[0]));

      if (org.addresses[0].phoneNumber.length > 1) {
        setPhoneTwo(checkNullValue(org.addresses[0].phoneNumber[1]));
      }
      setFaxNumber(checkNullValue(org.addresses[0].faxNumber[0]));


      if (org.addresses[0].faxNumber.length > 1) {
        setFaxTwoNumber(checkNullValue(org.addresses[0]?.faxNumber[1]));
      }

      setEmailOne(checkNullValue(org.addresses[0].email[0]));
      if (org.addresses[0].email.length > 1) {
        setEmailTwo(checkNullValue(org.addresses[0].email[1]));
      }
    }
    //setWebsite(org.addresses[0].);

    if (checkNullObject(org.parentCompanyName) && org.parentCompanyName.length) {
      setParentCompanyNameOne(checkNullValue(org.parentCompanyName[0]));

      if (org.parentCompanyName.length > 1) {
        setParentCompanyNameTwo(checkNullValue(org.parentCompanyName[1]));
      }
    }

    if (checkNullObject(org.addresses) && org.addresses.length > 1) {
      setSecondaryCountry(checkNullValue(org.addresses[1].country));
      setSecondaryResidence(checkNullValue(org.addresses[1].addressLine2));
      setSecondaryCity(checkNullValue(org.addresses[1].city));
      setSecondaryState_region(checkNullValue(org.addresses[1].state_region));
      setSecondaryZipCode(checkNullValue(org.addresses[1].zipCode));
    }
  }
  */

  function handleCancel() {
    navigate(RouteConstants.DASHBOARD);
    //alert('Cancel Button');

  }





  // const emailExp = '^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$';
  const emailExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  function handleSaveAsDraft() {
    setButtonClicked(true);
    let validate = true;

    //Begin Validation
    if (phoneOne != '' && !/^[0-9()-]+$/.test(phoneOne) || phoneOne.length >= 16) {
      setInputPhoneOne("Please enter a valid phone number");
      validate = false;
    } else {
      setInputPhoneOne("");
    }
    // old expr: ^(?!.*@[^\s@]+\.[^\s@]+$).*$
    if (emailOne != '' && /emailExp/.test(emailOne)) {
      setInputEmailOne("Please enter a valid email (example@email.com).")
      validate = false;
    } else {
      setInputEmailOne("");
    }
    // old expresssion: ^(?!.*@[^\s@]+\.[^\s@]+$).*$
    if (emailOne != '' && /emailExp/.test(emailOne) || phoneOne != '' && !/^[0-9()-]+$/.test(phoneOne) || phoneOne.length >= 16 || faxNumber != '' && !/^[0-9()-]+$/.test(faxNumber) || faxNumber.length >= 16) {
      validate = false;
    }
    //End Validation
    setFormValidated(validate)

    if (validate)
      alert('Draft Saved');


    navigate(RouteConstants.DASHBOARD);
  }




  function handleSaveAndCont() {
    if (disableFormFields) navigate(getRoute(pathname));
    //updateNavStore(pifId.current,formType);

    setButtonClicked(true);

    //BEGIN HANDLE SAVE AND COUNT VALIDATION
    let validate = true;
    if (Subawardee == '') {
      setInputSubAwardee("show");
      validate = false;
    } else {
      setInputSubAwardee("");
    }
    if (organizationType == '') {
      setInputTypeOfOrganization("show");
      validate = false;
    } else {
      setInputTypeOfOrganization("");
    }
    if (parentCompanyNameOne == '' && parentCompanyField) {
      setInputParentCompanyNameOne("show");
      validate = false;
    } else {
      setInputParentCompanyNameOne("");
    }

    if (usOrganization == '') {
      setInputUsOrganization("show");
      validate = false;
    } else {
      setInputUsOrganization("");
    }
    if (organizationType == '') {
      setInputTypeOfOrganization("show");
      validate = false;
    } else {
      setInputTypeOfOrganization("");
    }
    if (phoneOne == '') {
      setInputPhoneOne("Organization phone number is required");
      validate = false;
    } else if (!/^[0-9()-]+$/.test(phoneOne) || phoneOne.length >= 16) {
      setInputPhoneOne("Please enter a valid phone number");
      validate = false;
    } else {
      setInputPhoneOne("");
    }

    if (emailOne == '') {
      setInputEmailOne("Organization email is required");
      validate = false;
    } else if (/^(?!.*@[^\s@]+\.[^\s@]+$).*$/.test(emailOne)) {
      setInputEmailOne("Please enter a valid email (example@email.com).")
      validate = false;
    } else {
      setInputEmailOne("");
    }

    if (website == '') {
      setInputWebsite("show");
      validate = false;
    } else {
      setInputWebsite("");
    }

    if (orgAddress == '') {
      setInputOrgAddres("show");
      validate = false;
    } else {
      setInputOrgAddres("");
    }

    setFormValidated(validate)
    /**End validation */


    if (validate) {
      alert('Save and Continue');
      const org: IOrganization = prepareOrgDataForCreateOrUpdate();
      createOrUpdateOrg(org, []);
      navigate(getRoute(pathname), {
        state: {
          pifId: storedPifId,
          formType: formType
        }
      });
    }

    rowData.forEach((row) => {
      handleIndividualFileUpload(row.file);
    })
    //console.log("All files uploaded");


    // window.confirm("Save & Continue? \n\n" + "Legal Name of Prospective Subawardee: " + Subawardee + "\nParent Company Name: " + parentCompanyNameOne + "\nParent Company Name: " + parentCompanyNameTwo + "\nUS Organization: " + usOrganization + "\nAddress of Residence: " + primaryResidence + "\nCity: " +  primaryCity + "\nState/Province/Region: " + primaryState_region + "\nZip Code: " + primaryZipCode + "\nAdditional Address Country of Residence: " + secondaryCountry + "\nAdditional Address Residence: " + secondaryResidence + "\nAdditional City: " + secondaryCity + "\nAdditional State/Providence/Region: " + secondaryState_region + "\nAdditinoal Zip Code: " + secondaryZipCode + "\nPhone Number: " + phoneOne + "\nAdditional Phone Number: " + phoneTwo + "\nFax Number: " + faxNumber + "\nEmail: " + emailOne + "\nWebsite URL of PRospetive Subawardee: " + website);
    // navigate('/keyindividual', { state: {pifid:"",formType:""} }  ); 
  }//End Handle validation

  const prepareOrgDataForCreateOrUpdate = () => {
    const parrentCompaniesArray: string[] = [];
    if (parentCompanyNameOne) {
      parrentCompaniesArray.push(parentCompanyNameOne);
    }
    if (parentCompanyNameTwo) {
      parrentCompaniesArray.push(parentCompanyNameTwo);
    }

    let isUsOrg: boolean | undefined = undefined;

    if (usOrganization === 'yes') {
      isUsOrg = true;
    }
    if (usOrganization === 'no') {
      isUsOrg = false;
    }

    /*
      update Identification
    */
    const org: IOrganization = {
      pifId: storedPifId,
      orgId: orgId,
      objectName: orgId,
      mission: storedMission || "",
      bureau: storedBureau || "",
      organizationName: Subawardee,
      parentCompanyName: parrentCompaniesArray,
      isUsOrganization: isUsOrg,
      organizationType: organizationType,
      identificationNumber: "",
      identificationIssuedBy: "",
      primaryEmail: emailOne,
      alternativeEmail: emailTwo,
      website: website,
      primaryCountryCode: countryCodeOne,
      alternativeCountryCode: countryCodeTwo,
      primaryPhoneNumber: phoneOne,
      alternativePhoneNumber: phoneTwo,
      primaryAddress: orgAddress,
      alternateAddress: altAddress,
      parentCompanyId: [],
      identificationType: ""
    }
    return org;
  }

  const handleIndividualFileUpload = (file: File) => {
    //test PifId, DocumentId
    uploadFile(file, storedPifId, "ORG", "test");
    //console.log("Uploading file:", file.name)
  }

  const handleSubawardeeChange = (e: any) => {
    setSubawardee(e.currentTarget.value);
  };

  const handleOrganizationTypeChange = (e: any) => {
    setOrganizationType(e.currentTarget.value);
    const orgType = e.currentTarget.value;
    if (orgType === "Branch" || orgType === "Subsidiary") {
      // set showParentCompanyField to true
      showParentCompanyField(currVal => { return true });
    } else {
      // Need to reset to false to hide the Parent Company Field
      showParentCompanyField(false);
    }
  }

  const handleParentCompanyNameOneChange = (e: any) => {
    setParentCompanyNameOne(e.currentTarget.value);
  };

  const handleParentCompanyNameTwoChange = (e: any) => {
    setParentCompanyNameTwo(e.currentTarget.value);
  };

  const handleUsOrganizationChange = (e: any) => {
    setUsOrganization(e.currentTarget.value);
  };

  const handlePrimaryCountryChange = (e: any) => {
    setPrimaryCountry(e.currentTarget.value);
  };

  const handlePrimaryResidenceChange = (e: any) => {
    setPrimaryResidence(e.currentTarget.value);
  };

  const handlePrimaryCityChange = (e: any) => {
    setPrimaryCity(e.currentTarget.value);
  };

  const handlePrimaryState_regionChange = (e: any) => {
    setPrimaryState_region(e.currentTarget.value);
  };

  const handlePrimaryZipCodeChange = (e: any) => {
    setPrimaryZipCode(e.currentTarget.value);
  };

  const handleSecondaryCountryChange = (e: any) => {
    setSecondaryCountry(e.currentTarget.value);
  };

  const handleSecondaryCityChange = (e: any) => {
    setSecondaryCity(e.currentTarget.value);
  };

  const handleSecondaryResidenceChange = (e: any) => {
    setSecondaryResidence(e.currentTarget.value);
  };

  const handleSecondaryState_regionChange = (e: any) => {
    setSecondaryState_region(e.currentTarget.value);
  };

  const handleSecondaryZipCodeChange = (e: any) => {
    setSecondaryZipCode(e.currentTarget.value);
  };

  const handleFaxNumberChange = (e: any) => {
    setFaxNumber(e.currentTarget.value);
  };

  const handlePhoneOneChange = (e: any) => {
    setPhoneOne(e.currentTarget.value);
  };

  const handleCountryCodeOneChange = (e: any) => {
    setCountryCodeOne(e.currentTarget.value);
  };

  const handleCountryCodeTwoChange = (e: any) => {
    setCountryCodeTwo(e.currentTarget.value);
  };

  const handlePhoneTwoChange = (e: any) => {
    setPhoneTwo(e.currentTarget.value);
  };

  const handleEmailOneChange = (e: any) => {
    setEmailOne(e.currentTarget.value);
  };

  const handleEmailTwoChange = (e: any) => {
    setEmailTwo(e.currentTarget.value);
  };

  const handleFaxTwoChange = (e: any) => {
    setEmailTwo(e.currentTarget.value);
  };

  const handleWebsiteChange = (e: any) => {
    setWebsite(e.currentTarget.value);
  };

  const handleOrgAddress1 = (e: React.FormEvent<HTMLInputElement>): void => {
    setOrgAddress(e.currentTarget.value);
  }
  const handleOrgAddress2 = (e: React.FormEvent<HTMLInputElement>): void => {
    setAltOrgAddress(e.currentTarget.value);
  }
  const handleDisplayAdditionalAddress = (isShown: boolean): void => {
    //console.log("inside hande show additional")
    setDisplayAltOrgAddress(isShown);
  }

  var parentNameControls: IcontrolParentCompanyAttribs = {
    primaryOnChange: handleParentCompanyNameOneChange,
    primaryTextValue: parentCompanyNameOne,
    secondaryOnChange: handleParentCompanyNameTwoChange,
    secondaryTextValue: parentCompanyNameTwo,
    showSecondText: showSecondName
  };

  var phoneControls: IcontrolAttributes = {
    primaryOnChange: handlePhoneOneChange,
    primaryTextValue: phoneOne,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var phoneControls2: IcontrolAttributes = {
    primaryOnChange: handlePhoneTwoChange,
    primaryTextValue: phoneTwo,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var emailControls: IcontrolAttributes = {
    primaryOnChange: handleEmailOneChange,
    primaryTextValue: emailOne,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var emailControls2: IcontrolAttributes = {
    primaryOnChange: handleEmailTwoChange,
    primaryTextValue: emailTwo,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var faxControls: IcontrolAttributes = {
    primaryOnChange: handleFaxNumberChange,
    primaryTextValue: faxNumber,
    secondaryOnChange: handleFaxTwoChange,
    secondaryTextValue: faxTwo,
  };

  var addressControls: IaddressControlAttributes = {
    primaryResidenceValue: primaryResidence,
    primaryResidenceOnChange: handlePrimaryResidenceChange,
    primaryCountryValue: primaryCountry,
    primaryCountryOnChange: handlePrimaryCountryChange,
    secondaryCountryValue: secondaryCountry,
    secondaryCountryOnChange: handleSecondaryCountryChange,
    primaryCityValue: primaryCity,
    primaryCityOnChange: handlePrimaryCityChange,
    primaryState_regionValue: primaryState_region,
    primaryState_regionOnChange: handlePrimaryState_regionChange,
    primaryZipCodeValue: primaryZipCode,
    primaryZipCodeOnChange: handlePrimaryZipCodeChange,
    secondaryResidenceValue: secondaryResidence,
    secondaryResidenceOnChange: handleSecondaryResidenceChange,
    secondaryCityValue: secondaryCity,
    secondaryCityOnChange: handleSecondaryCityChange,
    secondaryState_regionValue: secondaryState_region,
    secondaryState_regionOnChange: handleSecondaryState_regionChange,
    secondaryZipCodeValue: secondaryZipCode,
    secondaryZipCodeOnChange: handleSecondaryZipCodeChange,
  };

  const uploadTitle = <strong> Upload Items for Organization </strong>;
  const contentText = ["Press 'Choose File' to begin.",
    <br />,
    "Limit 5 documents (File format: jpeg,tiff <25mb each)",
    <br />, <br />];
  let legalNameText = 'Name of the prospective subawardee to receive the award or other assistance';


  let navigate = useNavigate();

  let usOrgText = "If the entity is organized, incorporated, or otherwise formed in the\n";
  usOrgText += 'United States or under the laws of the United States, or any state therein, check Yes';

  // function to check for 


  return (
    <div className="">
      <div className="ms-3">
        {storedPifId && <p className='pif-id pt-5'><strong>PIF ID:</strong> {storedPifId}</p>}
      </div>
      <div className="orgTitleSection ms-2 fw-bold mb-0">
        Add an Organization (Organization Proposed to Receive Award or Other
        Assistance)
      </div>
      <div>
        {!formValidated && buttonClicked && (
          <div style={{ color: 'red', fontWeight: 'bold' }}>
            <p>Please correct highlighted errors below:</p>
          </div>
        )}
      </div>
      {/* Form border */}
      <div className="border rounded-2 w-100">

        {/* Insert Grid here */}
        <div className="orgz-grid">
          <div className="item-span4">
            <div className="mt-0 mb-2">
              <label style={{ color: "red" }}>* Denotes mandatory field</label>
            </div>
            <div className="item-span4">
              <OrganizationSearch
                section={"organization"}
                placeHolderText={"Start typing at least 2 minimum characters:"}
                searchOnChange={handleSearchOnChange}
                disabled={disableFormFields}
              />
            </div>
          </div>
          <div className="item-col1 ms-3" style={{marginTop:".12rem"}}>  {/* 1st Col */}
            <label className="asterisk">
              Legal Name of Prospective {awardeeType}
            </label>
            <HelpDialog
              title={`Legal Name of Prospective ${awardeeType}`}
              context={legalNameText}
            />
            <input className="form-control input-group-md"
              maxLength={100}
              type="text"
              placeholder={`Enter Legal Name of Prospective ${awardeeType}`}
              value={Subawardee}
              onChange={handleSubawardeeChange}
              disabled={disableFormFields}
            />
          {inputSubAwardee && (
            <p style={{ color: 'red', }}>
              Legal Name of Prospective {awardeeType} is required
            </p>
          )}
          </div>
          <div className="item-col3"> {/* 2nd Col */}
            <label className="asterisk second-col">Type of Organization</label>
            <HelpDialog
              title={"Type of Organization"}
              context={`Organization type of the prospective ${awardeeType}`}
            />
            <div className="">
              <select className="form-select form-select-md"
                value={organizationType}
                onChange={handleOrganizationTypeChange}
                disabled={disableFormFields}
                defaultValue=""
              >
                <option value="" disabled> Select Type of Organization </option>
                <option > Parent Organization </option>
                <option > Branch </option>
                <option > Subsidiary </option>
                <option > N/A </option>
                {/* {storedOrgTypes.map(x=>(<option value={x.display_name}>{x.display_name}</option>))}<></> */}
              </select>
            </div>
          </div>
          {/* TODO: Hide here initially
                 if "Branch" or "Subsidiary" chosen from dropdown - show */}
          {/* User Story #26945 */}

          <div className="item-col1">
            {parentCompanyField &&
              <ParentCompanyName
                title={"Parent Company Name"}
                controlAttributes={parentNameControls}
                disabled={disableFormFields}
              />}
            {inputparentCompanyNameOne && parentCompanyField && (
              <p style={{ color: 'red', marginLeft:"1em", marginTop:"-25px" }}>
                Parent Company Name is required
              </p>
            )}

          </div>
          <div className="item-col3 mt-1">
            <label className="asterisk">US Organization</label>
            <HelpDialog
              title={"US Organization"}
              context={usOrgText}
            />
            <div className="">

              <input
                type="radio"
                name="organization"
                value={"yes"}
                checked={usOrganization == "yes"}
                onChange={handleUsOrganizationChange}
                disabled={disableFormFields}
              />
              <label> Yes </label>
              <input
                style={{ marginLeft: "2em", }}
                type="radio" name="organization"
                value={"no"}
                checked={usOrganization == "no"}
                onChange={handleUsOrganizationChange}
                disabled={disableFormFields}
              />
              <label> No </label>
              {inputUsOrganization && (
                <p style={{ color: 'red' }}>
                  US Organization is required
                </p>
              )}
            </div>
          </div>
          <div className="item-span4 ms-3">
            <SingleAddressField
              id="primaryID"
              name="primaryAddress"
              title={`Address of Prospective ${awardeeType}`}
              placeholderText="Enter Address including Street, City, State/Province/Region and Zip Code"
              isAddtional={displayAltOrgAddress}
              handleShowIsAddtional={handleDisplayAdditionalAddress}
              altTitle={`Alternate Address of Prospective ${awardeeType}`}
              address1Value={orgAddress}
              address1EventChangeHandler={handleOrgAddress1}
              address2Value={altAddress}
              address2EventChangeHandler={handleOrgAddress2}
              disabled={disableFormFields}
            />
            {inputOrgAddres && (
              <p style={{ color: 'red', marginLeft: "10px", marginTop: "-40px" }}>
                Address of Prospective {awardeeType} is required
              </p>
            )}
          </div>
          <div className="item-col1">
            <AdditionalPhoneNumber
              title={"Organization Phone Number"}
              controlAttributes={phoneControls}
              isRedStarNeeded={true}
              countryData={countries}
              isOrg={true}
              countryCode={countryCodeOne}
              countryCodeOnChnage={handleCountryCodeOneChange}
              disabled={disableFormFields}
            />             
          {inputPhoneOne && (
            <p style={{ color: 'red', marginLeft: '1em' }}>
              {inputPhoneOne}
            </p>
          )}
          </div>

          <div className="item-col3">
            <AdditionalPhoneNumber
              title={"Alternate Organization Phone Number"}
              controlAttributes={phoneControls2}
              isRedStarNeeded={false}
              countryData={countries}
              isOrg={false}
              countryCode={countryCodeTwo}
              countryCodeOnChnage={handleCountryCodeTwoChange}
              disabled={disableFormFields}
            />
         
          </div>

          <div className="item-col1">
            <AdditionalFaxNumber
              title={"Organization Fax Number"}
              isAddNumberNeeded={false}
              controlAttributes={faxControls}
              countryData={countries}
              disabled={disableFormFields}
            />
            <div>
              {inputFaxNumber && (
                <p style={{ color: 'red', marginLeft: '20px', marginTop: 'px' }}>
                  {inputFaxNumber}
                </p>
              )} </div>
          </div>
          <div className="item-col3">
            <label className="label-div asterisk mb-1">
              Website URL of Prospective {awardeeType}
            </label>
            <HelpDialog
              title={`Website URL of Prospective ${awardeeType}`}
              context={`${awardeeType}'s website address/URL`}
            />
            <div className="control-div">
              <input className="form-control input-group-lg"
                maxLength={150}
                type="text"
                placeholder={`Enter Website URL of Prospective ${awardeeType}`}
                value={website}
                onChange={handleWebsiteChange}
                disabled={disableFormFields}
              />
            </div>
            <div>
              {inputWebsite && (
                <p style={{ color: 'red', marginLeft:"1em" }}>
                  Website URL of Prospective {awardeeType} is required
                </p>
              )} </div>
          </div>
          <div className="item-col1 mt-3">
            <AdditionalEmail
              title={"Organization Email"}
              isAddEmailNeeded={false}
              controlAttributes={emailControls}
              isRedStarNeeded={true}
              isPrimary={true}
              isPersonal={false}
              disabled={disableFormFields}
            ></AdditionalEmail>
            {inputEmailOne && (
              <p style={{ color: 'red', marginLeft: '1em' }}>
                {inputEmailOne}
              </p>
            )}
          </div>
          <div className="item-col3 mt-3 mb-1">
            <AdditionalEmail
              title={"Alternate Organization Email"}
              isAddEmailNeeded={false}
              controlAttributes={emailControls2}
              isRedStarNeeded={false}
              isPrimary={false}
              isPersonal={false}
              disabled={disableFormFields}
            ></AdditionalEmail>
          
            
            </div>
       
          <div className="fs-6 fw-bold item-span4 ms-4 mt-3">Documents for {`${awardeeType}  || ${awardeeType}'s`} Organization (limit 5 documents) </div>

          <div
            className="ag-theme-alpine mx-auto mt-2 mb-0 item-span4"
            style={{
              height: 250,
              marginTop: "1em",
              marginRight: "1em",
              marginLeft: "1em",
              width: "98%",
              justifyContent: "center",
              alignContent: "center",
              fontSize: "16px",
            }}
          >
            <AgGridReact rowData={rowData} columnDefs={colDefs} />
          </div>
          <div className="item-span4">
            <span className="ms-2 mt-0">
              <button
                className="my-3 bg-white"
                onClick={handleClickOpen}
                 disabled={disableFormFields}
              >
                <img src={icon_upload_file} alt="file upload icon"
                  className="me-2 ms-0"
                />
                Upload Documents (limit 5)
              </button>
            </span>
          </div>
        </div> { /* END CSS Grid */}
        <div className="d-flex justify-content-center gap-4 mt-3 mb-5"> 
            <button className="btn loginButton me-2" onClick={() => navigate(RouteConstants.DASHBOARD)}>Cancel</button>
            <button className="btn loginButton me-2 " onClick={handleSaveAndCont}>Save & Continue</button>
            <button className="btn loginButton"
              onClick={handleSaveAsDraft}
              disabled={disableFormFields}
            >Save Draft</button>
          </div>
          <FileUploadModal
          uploadType={"organization"}
          uploadTitle={"Upload Documents"}
          uploadDesc={"Upload Type"}
          uploadOptions={["Business License", "Articles of Formation", "Government Issued", "Other"]}
          placeHolderText={"Select Upload Type"}
          open={open}
          handleClickBtnClose={handleClickBtnClose}
          handleClickBtnOkay={handleClickBtnOkay} />
        
      </div> { /* END Border */}
    </div>
  );
}
// function enableStep(ORG_ADD_KEY_INDIVIDUALS: string) {
//   throw new Error("Function not implemented.");
// }

