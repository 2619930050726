import "../../common/Tiles/Tiles.css";
import "./OrganizationInfo.css";
import "bootstrap/dist/css/bootstrap.css";
import OrganizationSearch from "./OrganizationSearch";
import AdditionalAddress from "./AdditionalAddress";
import AdditionalEmail from "./AdditionalEmail";
import AdditionalLicense from "./AdditionalLicense";
import DualCitizenship from "./DualCitizenship";
import { useEffect, SyntheticEvent, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme
import { ColDef } from "ag-grid-community";
import {
  GridKeyIndividual,
  IaddressControlAttributes,
  IcontrolAttributes,
} from "../../../App.types";
import DeleteCellRenderer from "./DeleteCellRenderer";
import EditCellRenderer from "./EditCellRenderer";
import axios from "axios";
import UploadControl from "../../common/UploadControl";
import AdditionalIsCellPhoneNumber from "./AdditionalIsCellPhoneNumber";
import { ISearch } from "../../../Interfaces/ISearch";
import { IIndividual, initializeIIndividual } from "../../../Interfaces/IIndividual";
import { getIndividual } from "../../../Services/Api";
import { checkNullObject, checkNullValue } from "../../../Services/Utilities";
import { getPicklist,getIndividualsByPifId,keyIndividualsToGridKeyIndividual } from "../../../Services/Api";
import { IPicklist } from "../../../Interfaces/IPicklist";
import HelpDialog from "../../common/HelpDialog";
import { DatePicker } from "rsuite";
import { ICitizenship } from "../../../Interfaces/ICitizenship";
import { initialize5ICitizenship,initializeICitizenship} from "../../../Interfaces/ICitizenship";
import {filterEmptyCitizenship} from "../../../Services/Utilities";
import {useRef } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { textBirth, textLawful, textCitizenship, textDual } from "./KeyIndividualConstants";
import { RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import { useFormProps } from "../../stores/FormProperties";
import IAddress from "../../../Interfaces/IAddress";
import { useCountryStore } from "../../stores/CountryStore";
import { format} from 'date-fns';


// var individual: IIndividual;
axios.defaults.withCredentials=true;

export default function KeyIndividuals() {
  const { storeFormType, getRoute} = useNavStore();
  const {storedPifId, disableFormFields, storedMission, storedBureau, storedOrganisationId} = useFormProps();

  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [birthCountry, setBirthCountry] = useState<string>('');
  const [primCitCountryIssuedBy, setPrimCitCountryIssuedBy] = useState<string>('');
  const [primeGovIdentNum,setPrimeGovIdentNum]=useState<string>('');

  const [otherCitizenships, setOtherCitizenships] = useState<ICitizenship[]>(
    initialize5ICitizenship
  );


  const [message, setMessage] = useState<string | null>(null);


  const showMessage = (text: string) => {
    setMessage(text);
  }

  // const [countryData, setCountryData] = useState<ICountry[]>([]);
  const [orgIdentityTypes, setOrgIdentityTypes] = useState<IPicklist[]>([]);
  const [keyIndividuals, setKeyIndividuals] = useState<IIndividual[]>([]);

  //
  const keyIndividualsStateRef=useRef<IIndividual[]>();
  keyIndividualsStateRef.current=keyIndividuals;
  var keyIndGrid: GridKeyIndividual[] = [];

  const populateKeyIndividual = async (pifId:string) => {
    let response: IIndividual[] = await getIndividualsByPifId(storedPifId);//hardcode pifId
    keyIndGrid = keyIndividualsToGridKeyIndividual(response);
    setRowData(keyIndGrid);
    setKeyIndividuals(response);
    
    if(response.length > 0){
      setDisableContinue(false);
    } else{
      setDisableContinue(true);
    }
  }
 
  const {countries,updateCountries} = useCountryStore();
  
  useEffect(()=>{
    if (countries.length===0) updateCountries()
  },[])

  const populateIdentityType = async () => {
    let response: IPicklist[] = await getPicklist("individual_ident_type");
    setOrgIdentityTypes(response);
  }


  const [reRender,setReRender]=useState<number>(0);

  const {state, pathname}= useLocation();

  let inputPifId="";
  let formType="";


  useEffect(()=>{
    inputPifId = state && state.pifId? state.pifId:""
      formType = state && state.formType? state.formType:""
      if(inputPifId==="" ){
        pifId.current=storedPifId;
        //navigate(RouteConstants.DASHBOARD);
        }
      
  },[storedPifId]);


  useEffect(() => {
    populateIdentityType();
    populateKeyIndividual(storedPifId);
  }, [reRender])


  const [selectedCountryId, setSelectedCountryId] = useState<string>('');
  const [selectedGovernmentIdentification, setGovernmentIdentificationType] = useState<string>('');
  const [selectedGovernmentIssuedID, setGovernmentIssuedID] = useState<string>('');


  const pifId = useRef<string>("");
  var [indId,setIndId]=useState("");
  
  const [firstName, setFirstName] = useState("");
  var [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
  var [middleName, setMiddleName] = useState("");
  var [lastName, setLastName] = useState("");
  var [otherFirstName, setOtherFirstName] = useState("");
  var [otherMiddleName, setOtherMiddleName] = useState("");
  var [otherLastName, setOtherLastName] = useState("");
  var [rank, setRank] = useState("");
  var [idNumber, setIdNumber] = useState("");
  var [dualCitizenship, setDualCitizenship] = useState("");
  var [cityVillage, setCityVillage] = useState("");
  var [district, setDistrict] = useState("");
  var [governorate, setGovernorate] = useState("");
  var [dob, setDob] = useState<Date | null | undefined>();
  var [tribal, setTribal] = useState("");
  var [organizationName, setOrganizationName] = useState("");
  var [usCitizen, setUsCitizen] = useState("");
  var [permResident, setPermResident] = useState("");
  var [permResidentIdNumber, setPermResidentIdNumber] = useState("");
  var [isCellPhone, setIsCellPhone] = useState("");
  var [isAdditionalCellPhone, setIsAdditionalCellPhone] = useState("");
  var [phoneOne, setPhoneOne] = useState("");
  var [phoneTwo, setPhoneTwo] = useState("");
  var [emailOne, setEmailOne] = useState("");
  var [emailTwo, setEmailTwo] = useState("");
  var [licenseOne, setLicenseOne] = useState("");
  var [licenseTwo, setLicenseTwo] = useState("");
  var [primaryCountry, setPrimaryCountry] = useState("");
  var [secondaryCountry, setSecondaryCountry] = useState("");
  var [primaryResidence, setPrimaryResidence] = useState("");
  var [primaryCity, setPrimaryCity] = useState("");
  var [primaryState_region, setPrimaryState_region] = useState("");
  var [primaryZipCode, setPrimaryZipCode] = useState("");
  var [secondaryResidence, setSecondaryResidence] = useState("");
  var [secondaryCity, setSecondaryCity] = useState("");
  var [secondaryState_region, setSecondaryState_region] = useState("");
  var [secondaryZipCode, setSecondaryZipCode] = useState("");
  const [gender, setGender] = useState("");
  var [showAdditionalName, setAdditionalName] = useState(false);
  var [selectedFiles, setSelectedFiles] = useState([]);
  var [primaryAddressObjectId, setPrimaryAddressObjectId] = useState("");
  var [secondaryAddressObjectId, setSecondaryAddressObjectId] = useState("");



  const [errors, setErrors] = useState<{ [key: string]: string | null }>({
    firstName: null,
    lastName: null,
    cityVillage: null,
    placeOfBirthGovernorate: null,
    addressOfResidence: null,
    city: null,
    residenceState: null,
    zipCode: null,
  });
  const [errorsCount, setErrorsCount] = useState<string[]>([]);

  const [buttonClicked, setButtonClicked] = useState(false);

  const [radioBtnKey, setRadioBtnKey] = useState<string>();






  var [rowData, setRowData] = useState<GridKeyIndividual[]>();

  var [colDefs, setColDefs] = useState<ColDef<GridKeyIndividual>[]>([
    { field: "name", headerName: "Name" },
    { field: "id", headerName: "ID Number" },
    { field: "typeId", headerName: "ID Type" },
    { field: "role", headerName: "Role" },
    {
      headerName: "Edit",
      cellRenderer: EditCellRenderer,
      cellRendererParams: {
        onClick: handleEditClicked,
      },
    },
    {
      headerName: "Delete",
      cellRenderer: DeleteCellRenderer,
      cellRendererParams: {
        setDataRow: handleGridKeyIndividualDelete,
      },
    },
  ]);

  

  const handleCountrySelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
  }

  const handleCountrySelectBirthCountry= (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setBirthCountry(selectedCountry);
  }

  const handlePrimaryCountryIssuedBy= (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    setPrimCitCountryIssuedBy(selectedCountry);
  }


  const handlePrimaryIdentTypeNum= (event: any) => {
    setPrimeGovIdentNum(event as string);
  }





  const validateField = (fieldName: string, value: string) => {
    return;
    console.log(fieldName + ":" + value)
    if (!value?.trim() && (fieldName != 'US Lawful Permanent Resident' && isNotUsCitizen) && fieldName != 'Dual Citizenship' && fieldName != 'State/Province/Region' && fieldName != 'Organization Title or Rank' && fieldName != 'Government-Issued Photo ID #' && fieldName != 'Dual Citizenship Government Identification Type' && fieldName != 'Dual Citizenship Country' && fieldName != 'ID Country of Issuance' || firstName == '' || lastName == '' || dob == undefined || district == '' || gender == '' || primaryResidence == '' || idNumber == '' || emailOne == '' || /^(?!.*@[^\s@]+\.[^\s@]+$).*$/.test(emailOne) || /^(?!.*@[^\s@]+\.[^\s@]+$).*$/.test(emailTwo) || emailTwo == '' || usCitizen == '' || cityVillage == '' || governorate == '' || dualCitizenship == '' || primaryCity == '' || primaryState_region == '' || primaryZipCode == '' || phoneOne == '' || rank == '') {
      setErrorsCount(prevErrors => [...prevErrors, 'required errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + fieldName + ' is required ',
      }));
    } else if (fieldName == 'US Lawful Permanent Resident' && isNotUsCitizen && !permResident) {
      setErrorsCount(['US Lawful Permanent Resident', 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'US Lawful Permanent Resident is required ',
      }))
    }
    else if (phoneOne == '' || phoneTwo == '') {
      setErrorsCount(['US Lawful Permanent Resident', 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'US Lawful Permanent Resident is required ',
      }))
    }
    else if (isNotUsCitizen && !permResident) {
      setErrorsCount(['US Lawful Permanent Resident', 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'US Lawful Permanent Resident is required ',
      }))
    }
    else if (selectedCountry == '' || selectedCountry == "Select a Country of Citizenship") {
      setErrorsCount(['US Lawful Permanent Resident', 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'US Lawful Permanent Resident is required ',
      }))
    } else if (primaryCountry == '' || primaryCountry == "Select a Country") {
      setErrorsCount(['US Lawful Permanent Resident', 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'Country of Birth is required ',
      }))
    }
    else if (fieldName == 'US Citizen' && usCitizen === '') {
      setErrorsCount(prevErrors => [...prevErrors, 'errors'])

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'Please Correct Errors below.',
      }))
    }
    else if (selectedGovernmentIdentification === "Select Government Identification Type" || selectedGovernmentIdentification === "") {
      setErrorsCount(prevErrors => [...prevErrors, 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'Please Correct Errors below.',
      }))
    }
    else if (selectedGovernmentIssuedID === "Select Government Issued Photo ID #" || selectedGovernmentIssuedID === "") {
      setErrorsCount(prevErrors => [...prevErrors, 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'Please Correct Errors below.',
      }))
    } else if (fieldName == 'Primary Phone Number' && (value.length >= 16 || !/^[0-9()-]+$/.test(value)) && value.trim()) {
      setErrorsCount(prevErrors => [...prevErrors, 'errors']);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + '',
      }))
    } else if (fieldName == 'Alternate Phone Number' && (value.length >= 16 || !/^[0-9()-]+$/.test(value)) && value.trim()) {
      setErrorsCount(prevErrors => [...prevErrors, 'errors']);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + ' Please enter a valid phone number format 000-000-0000',
      }))
    } else if (fieldName == 'Dual Citizenship' && !value.trim()) {
      setErrorsCount(prevErrors => [...prevErrors, 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'Selecting A dual citizenship value is required',
      }))     // dualCountry===""
    } else if (fieldName === 'Dual Citizenship Country' && dualCitizenship === "Y") {
      setErrorsCount(prevErrors => [...prevErrors, 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'Dual citizenship country is required ',
      }))
    }
    else if (fieldName == 'State/Province/Region' && !value.trim()) {
      setErrorsCount(prevErrors => [...prevErrors, 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'State/Province/Region is required.',
      }))
    } else if (fieldName == 'Government-Issued Photo ID #' && !value.trim()) {
      setErrorsCount(prevErrors => [...prevErrors, 'errors'])

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'Government-Issued Photo ID# is required ',
      }))
    } else if (fieldName == 'Organization Title or Rank' && !value.trim()) {
      setErrorsCount(prevErrors => [...prevErrors, 'errors'])
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '' + 'Key individual must have a role.',
      }))
    }
    //    else if(fieldName != 'Zip Code' && fieldName != 'Primary Phone Number' && fieldName != 'Alternate Phone Number' && fieldName != 'Government Identification Type' && fieldName != 'Date of Birth' && fieldName != 'Country of Birth' && fieldName != 'US Lawful Permanent Resident' && fieldName!= 'Country of Residence' && fieldName!= 'US Citizen' && fieldName != 'Dual Citizenship Government Identification Type' && fieldName != 'ID Country of Issuance' && !/^[A-Za-z]+$/.test(value)) {
    //     setErrorsCount(prevErrors => [...prevErrors, 'errors'])

    //     setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [fieldName]: 'Only letters are allowed in ' + fieldName + '.',
    //   }));
    //  } 
    else {
      setErrorsCount([]);
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null }));
    }
  };

  const validateFields = (type: string) => {
    validateField('First Name', firstName);
    if (firstName === '' && type === 'continue') {
      setInputFirstName("show")
    } else {
      setInputFirstName("");
    }
    validateField('Last Name', lastName);
    if (lastName === '' && type === 'continue') {
      setInputLastName("show")
    } else {
      setInputLastName("");
    }
    validateField('Gender', gender);
    if (gender === '' && type === 'continue') {
      setInputGender("show")
    } else {
      setInputGender("");
    }
    validateField('US Citizen', usCitizen);
    if (usCitizen === '' && type === 'continue') {
      setInputusCitizen("show")
    } else {
      setInputusCitizen("");
    }
    validateField('US Lawful Permanent Resident', permResident);
    if (permResident === '' && type === 'continue') {
      setInputPermResident("show")
    } else {
      setInputPermResident("");
    }
    validateField('Country of Citizenship', selectedCountry);
    if ((selectedCountry === '' || selectedCountry === "Select a Country of Citizenship") && type === 'continue') {
      setInputCoutryofCitizenship("show")
    } else {
      setInputCoutryofCitizenship("");
    }
    validateField('Government Identification Type', selectedGovernmentIdentification);
    if (selectedGovernmentIdentification === '' && type === 'continue') {
      setInputGovernmentIdentificationType("show")
    } else {
      setInputGovernmentIdentificationType("");
    }
    { /* Put new validate Gov-IssuedID HERE */ }
    validateField('Government-Issued Photo ID #', selectedGovernmentIssuedID);
    if (selectedGovernmentIssuedID === '' && type === 'continue') {
      setInputGovernmentIssuedID("show")
    } else {
      setInputGovernmentIssuedID("");
    }

    validateField('ID Country of Issuance', inputIDCountryOfIssuance);
    if ((inputIDCountryOfIssuance === '' || inputIDCountryOfIssuance === selectedCountry) && type === 'continue') {
      setInputIDCountryOfIssuance("show")
    }
    else {
      setInputIDCountryOfIssuance("");
    }
    validateField('Village/City', cityVillage);
    if (cityVillage === '' && type === 'continue') {
      setInputcityVillage("show")
    } else {
      setInputcityVillage("");
    }
    validateField('District', district);
    if (district === '' && type === 'continue') {
      setInputDistrict("show")
    } else {
      setInputDistrict("");
    }
    validateField('Birth Province', governorate);
    if (governorate === '' && type === 'continue') {
      setInputGovernorate("show")
    } else {
      setInputGovernorate("");
    }
    // validateField('Country of Birth', countryofBirth);
    // if(countryofBirth ==''){
    //   setInputCountryOfBirth("show")
    // }else{
    //   setInputCountryOfBirth("");
    // }
    // validateField('Date of Birth', dob);
    // Different validation for Date Object---not a string
    // if (validator.isDate(dob?.toDateString)) {
    // }

    if (dob === undefined && type === 'continue') {
      setInputDateOfBirth("show")
    } else {
      setInputDateOfBirth('');
    }
    validateField('Dual Citizenship', dualCitizenship)
    if (dualCitizenship === '' && type === 'continue') {
      setInputDualCitizenship("show")
    } else {
      setInputDualCitizenship("");
    }
    //  if(isCellPhoneOne ==''){
    //   setInputIsCellPhoneOne("Is primary phone number cell yes or no required.")
    //  } else{
    //   setInputIsCellPhoneOne('')
    //  }
    //  if(isCellPhoneTwo ==''){
    //   setInputIsCellPhoneTwo("Is additional phone number cell yes or no required.")
    //  } else{
    //   setInputIsCellPhoneTwo('')
    //  }
    // validateField('Dual Citizenship Government Identification Type', dualGovernmentIdentificationType);
    // if(dualGovernmentIdentificationType ==''){ 
    //   setInputDualGovnermentIssuanceIDType("show")
    // }else{
    //   setInputDualGovnermentIssuanceIDType(""); 
    // }
    // if(dualIDCountryOfIssuance ==''){
    //   setInputDualIDCountryOfIssuance("show")
    // } else{
    //   setInputDualIDCountryOfIssuance("")
    // }
    validateField('Government-issued Photo ID', idNumber);
    if (idNumber === '' && type === 'continue') {
      setInputIdNumber("show")
    } else {
      setInputIdNumber("");
    }
    validateField('Country of Residence', primaryCountry)
    if ((primaryCountry === '' || primaryCountry === 'Select a Country') && type === 'continue') {
      setInputPrimaryCountry("show")
    } else {
      setInputPrimaryCountry("");
    } validateField('Address of Residence', primaryResidence);
    if (primaryResidence === '' && type === 'continue') {
      setInputPrimaryResidence("show")
    } else {
      setInputPrimaryResidence("");
    } validateField('City', primaryCity);
    if (primaryCity === '' && type === 'continue') {
      setInputCity("show")
    } else {
      setInputCity("");
    }
    validateField('State/Province/Region', primaryState_region)
    if (primaryState_region === '' && type === 'continue') {
      setInputPrimaryState_region("show")
    } else {
      setInputPrimaryState_region("");
    }

    validateField('Zip Code', primaryZipCode);
    if (primaryZipCode === '' && type === 'continue') {
      setInputPrimaryZipCode("show")
    } else {
      setInputPrimaryZipCode("");
    }

    validateField('Primary Phone Number', phoneOne);
    if (phoneOne === '' && type === 'continue') {
      setInputPhoneOne("Primary Phone Number is required");
    } else if ((!/^[0-9()-]+$/.test(phoneOne) || phoneOne.length >= 16) && phoneOne !== '') {
      setInputPhoneOne("Please enter a valid phone number");
    } else {
      setInputPhoneOne("");
    }


    validateField('Alternate Phone Number', phoneTwo);
    if (phoneTwo === '' && type === 'continue') {
      setInputPhoneTwo("Alternate Phone Number is required");
      setErrorsCount(prevErrors => [...prevErrors, 'required errors'])
    } else if ((!/^[0-9()-]+$/.test(phoneTwo) || phoneTwo.length >= 16) && phoneTwo !== '') {
      setInputPhoneTwo("Please enter a valid phone number");
    } else {
      setInputPhoneTwo("");
    }

    validateField('Organization Title or Rank', rank);

    validateField('Primary Email', emailOne);
    if (emailOne === '' && type === 'continue') {
      setInputPrimaryEmail("Primary email is required")
    } else if ((/^(?!.*@[^\s@]+\.[^\s@]+$).*$/.test(emailOne)) && emailOne !== '') {
      setInputPrimaryEmail("Please enter a valid email (example@email.com).")
    } else {
      setInputPrimaryEmail("");

    }
    validateField('Alternate Email', emailTwo);

    if (emailTwo === '' && type === 'continue') {
      setAlternateInputEmail("Alternate email is required")
    } else if ((/^(?!.*@[^\s@]+\.[^\s@]+$).*$/.test(emailTwo)) && emailTwo !== '') {
      setAlternateInputEmail("Please enter a valid email (example@email.com).")
    } else {
      setAlternateInputEmail("");
    }

    validateField('Organization Name', organizationName)

    if (organizationName === '' && type === 'continue') {
      setInputOrganizationName("show")
    } else {
      setInputOrganizationName("");
    }
  };



  const handleGovernmentIdentificationTypeChange = (event: any) => {
    const selectedGovernmentIdentification = event.target.value;
    setGovernmentIdentificationType(selectedGovernmentIdentification);
  }


  function handleSearchOnChange(event: SyntheticEvent<Element, Event>, value: ISearch) {
    if (value === null) return;

    const promise: Promise<IIndividual> = new Promise((resolve, reject) => {
      resolve(getIndividual(value.objectId));
      reject("error occured")
    })

    promise.then(res => {
      setIndividualValues(res);
    }).catch((error) => {
    })

  }


  const [inputFirstName, setInputFirstName] = useState('');
  const [inputLastName, setInputLastName] = useState('');
  const [inputGender, setInputGender] = useState('');
  const [inputusCitizen, setInputusCitizen] = useState('');
  const [inputCoutryofCitizenship, setInputCoutryofCitizenship] = useState('');
  const [inputGovernmentIdentificationType, setInputGovernmentIdentificationType] = useState('');
  const [inputGovernmentIssuedID, setInputGovernmentIssuedID] = useState('');
  const [inputIDCountryOfIssuance, setInputIDCountryOfIssuance] = useState('');
  const [inputDualCitizenship, setInputDualCitizenship] = useState('');
  const [inputcityVillage, setInputcityVillage] = useState('');
  const [inputDistrict, setInputDistrict] = useState('');
  const [inputCountryOfBirth, setInputCountryOfBirth] = useState('');
  const [inputGovernorate, setInputGovernorate] = useState('');
  const [inputDateOfBirth, setInputDateOfBirth] = useState('');
  const [inputPrimaryCountry, setInputPrimaryCountry] = useState('');
  const [inputPermResident, setInputPermResident] = useState('');
  const [inputPrimaryResidence, setInputPrimaryResidence] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputPrimaryState_region, setInputPrimaryState_region] = useState('');
  const [inputPrimaryZipCode, setInputPrimaryZipCode] = useState('');
  const [inputIdNumber, setInputIdNumber] = useState('');
  const [inputDualCountry, setInputDualCountry] = useState('');
  const [inputDualGovnermentIssuanceIDType, setInputDualGovnermentIssuanceIDType] = useState('');
  const [inputDualIDCountryOfIssuance, setInputDualIDCountryOfIssuance] = useState('');
  const [inputPrimaryEmail, setInputPrimaryEmail] = useState('');
  const [inputAlternateEmail, setAlternateInputEmail] = useState('');
  const [inputOrganizationName, setInputOrganizationName] = useState('');
  const [inputPhoneOne, setInputPhoneOne] = useState('');
  const [inputPhoneTwo, setInputPhoneTwo] = useState('');
  const [inputIsCellPhoneOne, setInputIsCellPhoneOne] = useState('');
  const [inputIsCellPhoneTwo, setInputIsCellPhoneTwo] = useState('');

  const [indiID, setIndiID] = useState<string | null>(null);

  

  function continueEvent() {
    
    navigate(RouteConstants.SUBAWARD_REVIEW,{
      state: {pifId:storedPifId,
      formType:formType}
    });
  }

  function saveEvent(event:any){
    
    handleSaveAndCont(event);
    
  }

  function addNewInd(event:any){
    setShowDetails(true);
  }

      // ***** Key Individual data pvs2_individual ******
    // const getKeyIndividualListURL: string = process.env.REACT_APP_API_URL + "/individual/getKeyIndividualList";

   const editIndividualURL: string = process.env.REACT_APP_API_URL + "/individual/getKeyIndividualEdit";

     async function loadEditInd(event:any){

      
    const indiID = window.prompt('Enter Individual to Edit');
    
    setIndiID(indiID);
    
    
    try{
      const response = await axios.post(editIndividualURL, indiID);

      const dataPresent = response.data;
      if(dataPresent.length === 0){
        alert('Individual Does Not Exist');
        setIsUpdateVisible(false)
      } else{
        handleisEditButtonClicked();
        setIsUpdateVisible(true);
      }
      
      const data = response.data[0];
      setGender(data.gender);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setMiddleName(data.middle_name);
      setPhoneOne(data.primary_phone_number);
      setRank(data.organization_title_or_rank);
      setOrganizationName(data.organization_title_or_rank);
      setPhoneTwo(data.alternative_phone_number);
      setPrimaryResidence(data.primary_address);
      setTribal(data.tribal_affiliation);
      setCityVillage(data.birth_city);
      setDistrict(data.birth_district);
      setUsCitizen("N")          
      setPermResident("N")        
      setShowDetails(true);

     console.log('Response', response.data);
   } catch(error) {
     console.error('Error posting individual data:', error);
   }

  }

  const editUpdateSelectedIndividualURL: string = process.env.REACT_APP_API_URL + "/individual/updateKeyIndividual";

  function updateSelectedIndi(event:any){
    event.preventDefault();

    axios.post(editUpdateSelectedIndividualURL, {
        "objectName": indiID,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        gender: gender,
        "birthCity": cityVillage,
        primaryAddress: primaryResidence,
        alternateAddress: secondaryResidence,
        email: emailOne,
        "alternateEmail": emailTwo,
        "tribalAffiliation": tribal,     
        "organizationTitleOrRank": rank,
        "birthDistrict": district,
        "phone": phoneOne,
        "alternatePhone": phoneTwo,
        "permResident": permResident,
        "govIssuePhotoIdType": selectedGovernmentIdentification,
        "completeGovIssuePhotoIDNumber": idNumber,
        "country": selectedCountry,
         mission: storedMission || "",
         bureau : storedBureau || ""

      })
      .then(response => {
        console.log('Response', response.data)
        alert(response.data);
      })
      .catch(error => {
        console.error('Error', error);
      });
      
}

  function cancelEvent(event:any){
    if(showDetails){
      clearIndividualValues();
      setShowDetails(false);
    } else{
      navigate(RouteConstants.DASHBOARD)
    }
  }

  const deleteIndividualURL: string = process.env.REACT_APP_API_URL + "/individual/deleteKeyIndividual";

  async function deleteEvent(event:any){
    const id = indiID;
    const isUserConfirmed = window.confirm('Are you sure you want to delete this record?')
    if(isUserConfirmed){
      clearIndividualValues();
      setShowDetails(false);
    } else{  
    }

    try{
      const response = await axios.post(deleteIndividualURL, id);

      // altert(response.data);

     console.log('Response', response.data);
   } catch(error) {
     console.error('Error Deleteing Individual Data:', error);
   }



  }

  // const selectIndiviudalEditsURL: string = process.env.REACT_APP_API_URL + "/individual/saveIndividual";

  // function editEvent(event:any){

  //   const response = await axios.get(selectIndiviudalEditsURL, {
    
  //   })

  //   const isUserConfirmed = window.confirm('Are you sure you want to delete this record?')
  //   if(isUserConfirmed){
  //     clearIndividualValues();
  //     setShowDetails(false);
  //   } else{
      
  //   }
  // }

  const saveSingleIndividualURL: string = process.env.REACT_APP_API_URL + "/individual/saveIndividual";

  function handleGenderSave (event: any) {
    event.preventDefault();

       axios.post(saveSingleIndividualURL, {
        gender: gender,
        firstName: firstName,
        lastName: lastName,

      })
      .then(response => {
        console.log('Response', response.data)
      })
      .catch(error => {
        console.error('Error', error);
      });
    } 



  //   function handleLoadEdit (event: any){

  //   useEffect(() => {

  //   const indiList = async () => { 

  //     try{
  //       const response = await axios.get<String[]>(editLoadIndvidualURL);

  //       setFirstName("Troy");

      

  //     } catch (error) {
  //       console.error('error did not get individual List')
  //     }
  //   }
  //   }
  // }

  async function handleSaveAndCont(event:any) {
    
    /*
      permanent residence details need to be added 
      Individual Address Details need to added 

      why is the validation bypassed ??
    */
    let primeCitizenship:ICitizenship[] =[
      {
        governmentIdentificationT:  selectedGovernmentIdentification,
        countryIssuedBy:  primCitCountryIssuedBy,
        governmentIssuedPhotoId:  idNumber,
        countryOfCitizenship:  selectedCountry,
        individualId:  indId,
        usOrNonUs:  usCitizen,
        isDualCitizen: dualCitizenship==='Y',
        objectName: indId,
        elementId:0
      }
    ];
    
    const primAddress : IAddress = {
      objectId: primaryAddressObjectId,
      objectName: indId,
      district: primaryState_region,
      street: primaryResidence,
      addressLine2:"",
      country: primaryCountry,
      countryCode: [],
      state: primaryState_region,
      city: primaryCity,
      zipCode: primaryZipCode,
      phoneNumber: [phoneOne,phoneTwo],
      faxNumber: [ primaryState_region ],
      email: [emailOne,emailTwo],
      isCellPhone: [isCellPhone, isAdditionalCellPhone]
   
    }

    let secAddress : IAddress = {
      objectId: secondaryAddressObjectId,
      objectName: indId,
      district: secondaryState_region,
      street: secondaryResidence,
      addressLine2:"",
      country: secondaryCountry,
      countryCode: [],
      state: secondaryState_region,
      city: secondaryCity,
      zipCode: secondaryZipCode,
      phoneNumber: [],
      faxNumber: [],
      email: [],
      isCellPhone: []
    }

    let individualInputData:IIndividual = {
      indId: indId,
      pifId: storedPifId,
      dateOfBirth: dob?format(dob, 'yyyy-MM-dd'):"",
      birthState: governorate,
      countryOfBirth: birthCountry,
      country: primaryCountry,
      alternativeCountryCode: "",
      primaryCountryCode: "",
      idCountryOfIssuance: primCitCountryIssuedBy,
      govIssuePhotoIdType: selectedGovernmentIdentification,
      govIssuePhotoIdTypeMultipleA: "",
      govIssuePhotoIdTypeMultipleB: "",
      completeGovIssuePhotoIDNumber: idNumber,
      completeGovIssuePhotoIDNumberMultipleA: "",
      completeGovIssuePhotoIDNumberMultipleB: "",
      lawfulPermanentResidentCardNumber:permResidentIdNumber,
      organizationTitleOrRank: rank,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      nameOfIndividual: firstName + " " + lastName,
      mission: storedMission,
      licensesAndCertifications: [licenseOne],
      associatedOrgId: storedOrganisationId,
      legacyId: "",
      awardeeId: "",
      birthDistrict: district,
      birthCity: cityVillage,
      gender: gender,
      occupation: organizationName,
      tribalAffiliation: tribal,
      citizenship: [...primeCitizenship,...filterEmptyCitizenship(otherCitizenships)],
      address :  [primAddress],
      district: district,
      villageCity: cityVillage,
      stateRegion: primaryState_region,
      usCitizen: usCitizen == "Y",
      permResident: permResident,
      organizationName: organizationName,
      phone: phoneOne,
      alternatePhone: phoneTwo,
      email: emailOne, 
      alternateEmail: emailTwo,
      isCellPhone: false,
      isAlternatePhoneCellPhone: false,
      title: rank,
      pifIdIndividual: "",
      idCountryOfIssuanceMultipleA: "",
      idCountryOfIssuanceMultipleB: "",
      bureau: storedBureau || "",
    
  }

  if (secAddress.street && secAddress.street.length >1 && secAddress.country){
    individualInputData.address = [...individualInputData.address, secAddress];
  }

  if (licenseTwo && licenseTwo.length >1){
    individualInputData.licensesAndCertifications = [...individualInputData.licensesAndCertifications, 
      licenseTwo];
  }

  let validate = true;
     
    validateField('First Name', firstName);
    if (firstName == '') {
      setInputFirstName("show")
      validate = false;
    } else {
      setInputFirstName("");
    }
    validateField('Last Name', lastName);
    if (lastName == '') {
      setInputLastName("show")
      validate = false;
    } else {
      setInputLastName("");
    }
    validateField('Gender', gender);
    if (gender == '') {
      setInputGender("show")
      validate = false;
    } else {
      setInputGender("");
    }
    validateField('US Citizen', usCitizen);
    if (usCitizen == '') {
      setInputusCitizen("show")
      validate = false;
    } else {
      setInputusCitizen("");
    }
    validateField('US Lawful Permanent Resident', permResident);
    if (permResident == '') {
      setInputPermResident("show")
    } else {
      setInputPermResident("");
    }
    validateField('Country of Citizenship', selectedCountry);
    if (selectedCountry == '' || selectedCountry == "Select a Country of Citizenship") {
      setInputCoutryofCitizenship("show")
      validate = false;
    } else {
      setInputCoutryofCitizenship("");
    }
    validateField('Government Identification Type', selectedGovernmentIdentification);
    if (selectedGovernmentIdentification == '') {
      setInputGovernmentIdentificationType("show")
      validate = false;
    } else {
      setInputGovernmentIdentificationType("");
    }
    { /* Put new validate Gov-IssuedID HERE */ }
    validateField('Government-Issued Photo ID #', selectedGovernmentIssuedID);
    if (selectedGovernmentIssuedID == '') {
      setInputGovernmentIssuedID("show")
    } else {
      setInputGovernmentIssuedID("");
    }


    validateField('ID Country of Issuance', inputIDCountryOfIssuance);
    if (primCitCountryIssuedBy == '') {
      setInputIDCountryOfIssuance("show")
      validate = false;
    }
    else {
      setInputIDCountryOfIssuance("");
    }
    validateField('Village/City', cityVillage);
    if (cityVillage == '') {
      setInputcityVillage("show")
      validate = false;
    } else {
      setInputcityVillage("");
    }
    validateField('District', district);
    if (district == '') {
      setInputDistrict("show")
      validate = false;
    } else {
      setInputDistrict("");
    }
    validateField('Birth Province', governorate);
    if (governorate == '') {
      setInputGovernorate("show")
      validate = false;
    } else {
      setInputGovernorate("");
    }
    validateField('Country of Birth', inputCountryOfBirth);
    if (birthCountry == '') {
      setInputCountryOfBirth("show")
      validate = false;
    } else {
      setInputCountryOfBirth("");
    }
    // Check for valid date 
    // validateField('Date of Birth', dob);
    if (!dob) {
      setInputDateOfBirth("show")
      validate = false;
    } else {
      setInputDateOfBirth("");
    }
    validateField('Dual Citizenship', dualCitizenship)
    if (dualCitizenship == '') {
      setInputDualCitizenship("show")
      validate = false;
    } else {
      setInputDualCitizenship("");
    }
    validateField('Government-issued Photo ID', idNumber);
    if (idNumber == '') {
      setInputIdNumber("show")
      validate = false;
    } else {
      setInputIdNumber("");
    }
    validateField('Country of Residence', primaryCountry)
    if (primaryCountry == '' || primaryCountry == 'Select Country of Residence') {
      setInputPrimaryCountry("show")
      validate = false;
    } else {
      setInputPrimaryCountry("");
    } validateField('Address of Residence', primaryResidence);
    if (primaryResidence == '') {
      setInputPrimaryResidence("show")
      validate = false;
    } else {
      setInputPrimaryResidence("");
    } validateField('City', primaryCity);
    if (primaryCity == '') {
      setInputCity("show")
      validate = false;
    } else {
      setInputCity("");
    }
    validateField('State/Province/Region', primaryState_region)
    if (primaryState_region == '') {
      setInputPrimaryState_region("show")
      validate = false;
    } else {
      setInputPrimaryState_region("");
    }

    validateField('Zip Code', primaryZipCode);
    if (primaryZipCode == '') {
      setInputPrimaryZipCode("show")
      validate = false;
    } else {
      setInputPrimaryZipCode("");
    }

    validateField('Primary Phone Number', phoneOne);
    if (phoneOne == '') {
      setInputPhoneOne("Primary Phone Number is required");
      validate = false;

    } else if (!/^[0-9()-]+$/.test(phoneOne) || phoneOne.length >= 16) {
      setInputPhoneOne("Please enter a valid phone number");
      validate = false;
    } else {
      setInputPhoneOne("");
    }

    validateField('Alternative Phone Number', phoneTwo);
    if (phoneTwo == '') {
      setInputPhoneTwo("Alternative Phone Number is required");
      validate = false;

    } else if (!/^[0-9()-]+$/.test(phoneTwo) || phoneTwo.length >= 16) {
      setInputPhoneTwo("Please enter a valid phone number");
      validate = false;
    } else {
      setInputPhoneTwo("");
    }

    validateField('Organization Title or Rank', rank);

    validateField('Primary Email', emailOne);
    if (emailOne == '') {
      setInputPrimaryEmail("Primary email is required")
      validate = false;
    } else if (/^(?!.*@[^\s@]+\.[^\s@]+$).*$/.test(emailOne)) {
      setInputPrimaryEmail("Please enter a valid email (example@email.com).")
      validate = false;
    } else {
      setInputPrimaryEmail("");
    }

    validateField('Alternate Email', emailTwo);
    
    validateField('Organization Name', organizationName)
    if (organizationName == '') {
      setInputOrganizationName("show")
      validate = false;
    } else {
      setInputOrganizationName("");
    }

    if (!validate) return;

    try{
      const response = await axios.post(process.env.REACT_APP_API_URL + '/individual/createOrUpdate', individualInputData);
         console.log('Response', response.data);
      } catch(error) {
        console.error('Error posting individual data:', error);
      }

    setButtonClicked(true);
    //Ke(individualInputData,selectedFiles);
    //setReRender(prevReRender => prevReRender+1);

{/* Comment out for now - may not be needed */}
    // if(lastName !== "" && district !==""){
    //   createOrUpdateKeyIndvidual(individualInputData,selectedFiles);
    //   setReRender(prevReRender => prevReRender+1);

    //   var ind: GridKeyIndividual = {
    //     cellId: individualInputData?.indId,
    //     id: individualInputData?.citizenships[0]?.governmentIssuedPhotoId,
    //     name: individualInputData?.firstName + " " + individualInputData?.middleName + " " + individualInputData?.lastName,
    //     role: individualInputData?.organizationTitleOrRank,
    //     typeId: individualInputData?.citizenships[0]?.governmentIdentificationT,
    //   };

    //   keyIndGrid.push(ind);
    //   setRowData(keyIndGrid);
    //  } 
    //  else{
    //   event.preventDefault();
    //   return
    //  }
     
     event.preventDefault();
     populateKeyIndividual(storedPifId);
     
     setDisableContinue(false);
     setShowDetails(false);
     

  }//End handleSaveAndCont

  let navigate = useNavigate();

  const [btnName,setBtnName] = useState("");



  const handleFirstNameChange = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleOtherFirstNameChange = (e: any) => {
    setOtherFirstName(e.target.value);
  };

  const handleMiddleNameChange = (e: any) => {
    setMiddleName(e.target.value);
  };

  const handleOtherMiddleNameChange = (e: any) => {
    setOtherMiddleName(e.target.value);
  };

  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value);
  };

  const handleOtherLastNameChange = (e: any) => {
    setOtherLastName(e.target.value);
  };

  const handleRankChange = (e: any) => {
    setRank(e.target.value);
  };

  const handleIdNumberChange = (e: any) => {
    setIdNumber(e.target.value);
  };

  const handleCityVillageChange = (e: any) => {
    setCityVillage(e.target.value);
  };

  const handleDistrictChange = (e: any) => {
    setDistrict(e.target.value);
  };

  const handleGovernorateChange = (e: any) => {
    setGovernorate(e.target.value);
  };

  const handleDobChange = (selectedDate: Date | null | any) => {
    setDob(selectedDate);
  };

  const handleTribalChange = (e: any) => {
    setTribal(e.target.value);
  };

  const handleOrganizationNameChange = (e: any) => {
    setOrganizationName(e.target.value);
  };

  const handleDualCitizenshipChange = (e: any) => {
    setDualCitizenship(e.currentTarget.value);
  };

  const handleUsCitizenshipChange = (e: any) => {
    setUsCitizen(e.currentTarget.value);
  };

  const handlePermResidentChange = (e: any) => {
    setPermResident(e.currentTarget.value);
  };

  const handlePermResidentIdNumberChange = (e: any) => {
    setPermResidentIdNumber(e.currentTarget.value);
  };

  const handleIsCellPhoneChange = (e: any) => {
    setIsCellPhone(e.currentTarget.value);
  };

  const handleIsAdditionalCellPhoneChange = (e: any) => {
    setIsAdditionalCellPhone(e.currentTarget.value);
  };

  const handlePhoneOneChange = (e: any) => {
    setPhoneOne(e.currentTarget.value);
    setRadioBtnKey("phoneOne");
    //console.log ("Inside handleONEHANGE, Radio btn key: " + radioBtnKey);
  };

  const handlePhoneTwoChange = (e: any) => {
    setPhoneTwo(e.currentTarget.value);
    setRadioBtnKey("phoneTwo");
  };

  const handleEmailOneChange = (e: any) => {
    setEmailOne(e.currentTarget.value);
  };

  const handleEmailTwoChange = (e: any) => {
    setEmailTwo(e.currentTarget.value);
  };

  const handleLicenseOneChange = (e: any) => {
    setLicenseOne(e.currentTarget.value);
  };

  const handleLicenseTwoChange = (e: any) => {
    setLicenseTwo(e.currentTarget.value);
  };

  const handlePrimaryCountry = (e: any) => {
    setPrimaryCountry(e);
  };

  const handleSecondaryCountry = (e: any) => {
    setPrimaryCountry(e.currentTarget.value);
  };


  const handlePrimaryResidenceChange = (e: any) => {
    setPrimaryResidence(e.currentTarget.value);
  };

  const handlePrimaryCityChange = (e: any) => {
    setPrimaryCity(e.currentTarget.value);
  };

  const handlePrimaryState_regionChange = (e: any) => {
    setPrimaryState_region(e.currentTarget.value);
  };

  const handlePrimaryZipCodeChange = (e: any) => {
    setPrimaryZipCode(e.currentTarget.value);
  };

  const handleSecondaryCityChange = (e: any) => {
    setSecondaryCity(e.currentTarget.value);
  };

  const handleSecondaryResidenceChange = (e: any) => {
    setSecondaryResidence(e.currentTarget.value);
  };

  const handleSecondaryState_regionChange = (e: any) => {
    setSecondaryState_region(e.currentTarget.value);
  };

  const handleSecondaryZipCodeChange = (e: any) => {
    setSecondaryZipCode(e.currentTarget.value);
  };

  const handleOnGenderChange = (e: any) => {
    setGender(e.currentTarget.value);
  };

  const handleBtnNameChange = (e: any) => {
    setBtnName(e.currentTarget.value);
  };

  var phoneControls: IcontrolAttributes = {
    primaryOnChange: handlePhoneOneChange,
    primaryTextValue: phoneOne,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var phoneControls2: IcontrolAttributes = {
    primaryOnChange: handlePhoneTwoChange,
    primaryTextValue: phoneTwo,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var emailControls: IcontrolAttributes = {
    primaryOnChange: handleEmailOneChange,
    primaryTextValue: emailOne,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var emailControls2: IcontrolAttributes = {
    primaryOnChange: handleEmailTwoChange,
    primaryTextValue: emailTwo,
    secondaryOnChange: "",
    secondaryTextValue: "",
  };

  var licenseControls: IcontrolAttributes = {
    primaryOnChange: handleLicenseOneChange,
    primaryTextValue: licenseOne,
    secondaryOnChange: handleLicenseTwoChange,
    secondaryTextValue: licenseTwo,
  };

  var addressControls: IaddressControlAttributes = {
    primaryCountryValue: primaryCountry,
    primaryCountryOnChange: handlePrimaryCountry,
    secondaryCountryValue: secondaryCountry,
    secondaryCountryOnChange: handleSecondaryCountry,
    primaryResidenceValue: primaryResidence,
    primaryResidenceOnChange: handlePrimaryResidenceChange,
    primaryCityValue: primaryCity,
    primaryCityOnChange: handlePrimaryCityChange,
    primaryState_regionValue: primaryState_region,
    primaryState_regionOnChange: handlePrimaryState_regionChange,
    primaryZipCodeValue: primaryZipCode,
    primaryZipCodeOnChange: handlePrimaryZipCodeChange,
    secondaryResidenceValue: secondaryResidence,
    secondaryResidenceOnChange: handleSecondaryResidenceChange,
    secondaryCityValue: secondaryCity,
    secondaryCityOnChange: handleSecondaryCityChange,
    secondaryState_regionValue: secondaryState_region,
    secondaryState_regionOnChange: handleSecondaryState_regionChange,
    secondaryZipCodeValue: secondaryZipCode,
    secondaryZipCodeOnChange: handleSecondaryZipCodeChange,
  };

  var isGenderOther: boolean = gender === "O";
  var isNotUsCitizen: boolean = usCitizen === "N";
  var isLawfulResident: boolean = permResident === "Y";
  var isEitherCitzenOrResident: boolean =
    usCitizen === "Y" || permResident === "Y";




  function handleGridKeyIndividualDelete(cellId: number) {
    //** 

    //*
  }

  function handleEditClicked(cellId: string) {
    //TODO: will need to add url with user ID parameter

    let focusedIndividual:IIndividual|undefined=initializeIIndividual();

    if(keyIndividualsStateRef.current!==undefined){
        focusedIndividual=keyIndividualsStateRef.current.find(p => {
          return p.indId===cellId
      
      });
    }//End If
    if(focusedIndividual!==undefined){
      setIndividualValues(focusedIndividual);
    }
    else{
      alert("ERROR");
      setIndividualValues(keyIndividuals[0]);
    }
    setShowDetails(true);
  }


  function handleOtherCitizenshipChange(citizehsips:ICitizenship[]){
  }

  function toDate(text : String): Date{
    var strings = text.split("/");

    return new Date(parseInt(strings[2]), parseInt(strings[0]) - 1, parseInt(strings[1]))
  }

  function setIndividualValues(ind: IIndividual) {
    //Clear out values
    clearIndividualValues();

    
    pifId.current=(checkNullValue(ind.pifId));
    setIndId(checkNullValue(ind.indId));
    setFirstName(checkNullValue(ind.firstName));
    setMiddleName(checkNullValue(ind.middleName));
    setLastName(checkNullValue(ind.lastName));
    setRank(checkNullValue(ind.organizationTitleOrRank));
    setCityVillage(checkNullValue(ind.birthCity));
    setDistrict(checkNullValue(ind.birthDistrict));
    if (checkNullValue(ind.dateOfBirth))
      setDob(toDate(ind.dateOfBirth));
    setGovernorate(checkNullValue(ind.birthState));
    setOrganizationName(checkNullValue(ind.occupation));
    setTribal(checkNullValue(ind.tribalAffiliation));
    setBirthCountry(checkNullValue(ind.countryOfBirth))

    //permanent residence field not found
    if (ind?.citizenship && checkNullObject(ind?.citizenship) && ind?.citizenship.length) {
      //Adding main citizenship
      setSelectedCountry(checkNullValue(ind.citizenship[0].countryOfCitizenship))
      setGovernmentIdentificationType(checkNullValue(ind.citizenship[0].governmentIdentificationT))
      setIdNumber(checkNullValue(ind.citizenship[0].governmentIssuedPhotoId));
      setPrimCitCountryIssuedBy(checkNullValue(ind.citizenship[0].countryIssuedBy))


      setUsCitizen(checkNullValue(ind.citizenship[0].usOrNonUs));
      setPermResident(checkNullValue(ind.citizenship[0].usOrNonUs));
      setDualCitizenship(checkNullValue(ind.citizenship[0].isDualCitizen));

      if(ind.citizenship.length>1){
        setDualCitizenship("Y");
        let cit1:ICitizenship=initializeICitizenship;
        cit1.governmentIdentificationT=ind.citizenship[1].governmentIdentificationT;
        cit1.countryIssuedBy=ind.citizenship[1].countryIssuedBy;
        cit1.countryOfCitizenship=ind.citizenship[1].countryOfCitizenship;
        cit1.governmentIssuedPhotoId=ind.citizenship[1].governmentIssuedPhotoId;

        setOtherCitizenships([cit1])
        

       
        //setSelectedCountry(checkNullValue(ind.citizenships[1].countryOfCitizenship))
        //setGovernmentIdentificationType(checkNullValue(ind.citizenships[1].governmentIdentificationT))
        //setIdNumber(checkNullValue(ind.citizenships[1].governmentIssuedPhotoId));
        //setPrimCitCountryIssuedBy(checkNullValue(ind.citizenships[0].countryIssuedBy))
      }
      else{
        setDualCitizenship("N");
      }
     

    }
    
    if(ind?.licensesAndCertifications) setLicenseOne(checkNullValue(ind.licensesAndCertifications[0]));
    if(ind?.licensesAndCertifications && ind?.licensesAndCertifications.length > 0) setLicenseTwo(checkNullValue(ind.licensesAndCertifications[0]));


  
    if (checkNullObject(ind.address) && ind.address.length > 0) {
      setPrimaryResidence(checkNullValue(ind.address[0].street));
      setPrimaryCity(checkNullValue(ind.address[0].city));
      setPrimaryState_region(checkNullValue(ind.address[0].state));
      setPrimaryCountry(checkNullValue(ind.address[0].country));
      setPrimaryZipCode(checkNullValue(ind.address[0].zipCode));
      setPrimaryAddressObjectId(ind.address[0].objectId);

      if(ind.address[0].email?.length>0){
        setEmailOne(ind.address[0].email[0]);
      }

      if(ind.address[0].email?.length>1){
        setEmailTwo(ind.address[0].email[1]);
      }

      if(ind.address[0].phoneNumber?.length>0){
        setPhoneOne(ind.address[0].phoneNumber[0]);
      }

      if(ind?.address[0].phoneNumber?.length>1){
        setPhoneTwo(ind.address[0].phoneNumber[1]);
      }

      if(ind.address[0].isCellPhone?.length>0){
        setIsCellPhone(ind.address[0].isCellPhone[0]);
      }

      if(ind?.address[0].isCellPhone?.length>1){
        setIsAdditionalCellPhone(ind.address[0].isCellPhone[1]);
      }

      if(ind.address[0].faxNumber?.length>0){
        setPrimaryState_region(ind.address[0].faxNumber[0]);
      }

    }
    setGender(ind.gender);


  }

  function clearIndividualValues() {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setIdNumber("");
    setRank("");
    setCityVillage("");
    setDistrict("");
    setDob(prev => undefined);
    setGovernorate("");
    setOrganizationName("");
    setTribal("");
    setUsCitizen("");
    setPermResident("");
    setDualCitizenship("");
    setPhoneOne("");
    setPhoneTwo("");
    setPrimaryResidence("");
    setPrimaryCity("");
    setPrimaryState_region("");
    setPrimaryZipCode("");
    setBirthCountry("");
    setOtherFirstName("");
    setOtherMiddleName("");
    setOtherLastName("");
    setGender("");
    setSelectedCountry("");
    setPrimCitCountryIssuedBy("");
    setEmailOne("");
    setEmailTwo("");
    setLicenseOne("")
    setLicenseTwo("");
    setIsCellPhone("");
    setPrimaryCountry("");

    
    
  }

  const [isMessageVisible, setMessageVisibility] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [isUpdateVisible, setIsUpdateVisible] = useState<boolean>(false);

  const toggleValidation = () => {
    setMessageVisibility(!isMessageVisible);

  };

  const handleisEditButtonClicked = () => {
    setIsEditButtonClicked(true);
  }

  // const KeyIndividuals: React.FC = () => {
    const [uploadStatus, setUploadStatus] = useState({
      isLawfulResident: false,
      citizenship: false,
      // field3: false,
    });


  return (
    <div>
  { storedPifId &&<p className='text-start pif-id' style={{paddingTop:"5px"}}><strong>PIF ID:</strong> {storedPifId}</p>}

      <div style={{display: showDetails ? "none" : "block"}}>
        <div className="row" style={{paddingBottom: "10px", paddingTop:"20px"}}>
        <div className="orgTitleSection fw-bold mb-0 col-5">
          Key Individuals (Add Key Individual or edit existing Key Individual)
        </div>

        <div className="col-2">
          <button className="btn loginButton" onClick={addNewInd} disabled={disableFormFields}>+ Add Key Individual</button>

         {/* <button style={{marginLeft: '-40px', marginTop: '50px'}} className="btn loginButton" onClick={loadEditInd}>+ Edit Existing Key Individual</button>  */}

              {/* To use textbox instead of a window prompt */}
          {/* <input type="text"
                  placeholder="Enter PIF-ID"
                  style={{marginLeft: '-300px', marginTop: '50px'}}
              /> */}
        </div>


        
        </div>
        <div
          className="ag-theme-alpine mx-auto"
          style={{
            height: 250,
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            fontSize: "16px",
          }}
        >

          <AgGridReact rowData={rowData} columnDefs={colDefs} />

        </div>
      </div>

      <div style={{display:showDetails ? "block" : "none"}}>
        <div className="orgTitleSection fw-bold mb-0" style={{ marginLeft: "1.5em" }}>
          Key Individual (Enter individual to add to the
          PIF submission)
        </div>
      
        <div className="contentSection" style={{ marginTop: "1em" }}>

          {errorsCount.length > 0 && (
            <div>
              <p style={{ color: 'red', fontWeight: 'bold' }}> Please correct errors highlighted below.</p>
            </div>
          )}


          <div
            className="textFormat"
            style={{ marginBottom: "2em" }}
          >
            <div className="row ms-2 mb-2">
              <label style={{ color: "red" }}>* Denotes mandatory field</label>
            </div>
            <div className="col control-div w-80 me-4 pe-3">
              <OrganizationSearch
                section={"individual"}
                placeHolderText={"Start typing at least 2 minimum characters:"}
                searchOnChange={handleSearchOnChange} disabled={false}              />
            </div>

            <div className="row" style={{ margin: "1em" }}>
              <div className="textFormat row">
                <div className="col-9 mb-1"> <label>Name (listed on government photo ID) </label>
                </div>

              </div>
              <div className="rounded-2 contentSection textFormat">
                <div className="row row-cols-3 ms-3">
                  <div className="col-4 me-5">
                    <label className="row label-div asterisk">First Name</label>
                    <div className="row control-div">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter First Name"
                        onChange={handleFirstNameChange}
                        value={firstName}
                      />
                    </div>
                    <div>
                      {inputFirstName && (
                        <p className="error" style={{marginLeft:"-11px"}}>
                          First Name is required
                        </p>
                      )} </div>
                  </div>
                  <div className="col-4 me-5">
                    <label className="row label-div">Middle Name</label>
                    <div className="row control-div">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter Middle Name"
                        onChange={handleMiddleNameChange}
                        value={middleName}
                      />
                    </div>
                  </div>
                  <div className="col-3 flex-fill me-4">
                    <label className="row label-div asterisk">Last Name</label>
                    <div className="row control-div">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter Last Name"
                        onChange={handleLastNameChange}
                        value={lastName}
                      />
                    </div>
                    <div>
                      {inputLastName && (
                        <p style={{ color: 'red', marginLeft:'-11px'}}>
                          Last Name is required
                        </p>
                      )} </div>
                  </div>

                </div>
              </div>

              
            </div>

            
            <div className="row" style={{ margin: "1em" }}>
              <div className="textFormat row">
                <div className="col-9 mb-1"> Other Names Used </div>
              </div>
              <div className="rounded-2 contentSection textFormat">
              <div className="row row-cols-3 ms-3">

              <div className="col-4 me-5">
                    <label className="row label-div">First Name</label>
                    <div className="row control-div">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter First Name"
                        onChange={handleOtherFirstNameChange}
                        value={otherFirstName}
                      />
                    </div>
                  </div>


                    <div className="col-4 me-5">
                    <label className="row label-div">Middle Name</label>
                    <div className="row control-div">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter Middle Name"
                        onChange={handleOtherMiddleNameChange}
                        value={otherMiddleName}
                      />
                    </div>
                    </div>

                    <div className="col-3 flex-fill me-4">
                    <label className="row label-div">Last Name</label>
                    <div className="row control-div">
                      <input
                        className="form-control input-group-lg"
                        maxLength={50}
                        type="textbox"
                        placeholder="Enter Last Name"
                        onChange={handleOtherLastNameChange}
                        value={otherLastName}
                      />
                    </div>
                  </div>


                  </div>

                </div>
                <div className="col-4 label-div ms-auto mt-1 pe-0">
              
                </div>

              </div>
        


            <div className="row row-cols-2">
              <div className="col-5 flex-fill ms-4">
                <label className="label-div asterisk mb-1">
                  Gender (listed on government photo ID)
                </label>
                <HelpDialog
                  title={"Gender on government-issued photo ID"}
                  context='Gender as it appears on the government-issued identification'
                />
                <div className="control-div">
              <input
                      className="form-control input-group-lg"
                      maxLength={50}
                      type="textbox"
                      placeholder="Enter gender on government-issued photo ID"
                      onChange={handleOnGenderChange}
                      value={gender}
                    />
              </div>
                <div>
                  {inputGender && (
                    <p style={{ color: 'red', marginLeft: '19px' }}>
                      Gender is required
                    </p>
                  )} </div>
              </div>

              <div className="col flex-fill ps-5">
                <label className="label-div asterisk ms-4 ps-2">US Citizen</label>
                <HelpDialog
                  title={"U.S. Citizen?"}
                  context='Yes for U.S. citizen and No for non-U.S. citizen'
                />
                <div className="control-div">
                  <div className="form-check form-check-inline">
                    <input
                      id="radio-yes"
                      type="radio"
                      name="citizenship"
                      value={"Y"}
                      checked={usCitizen === "Y"}
                      onChange={handleUsCitizenshipChange}
                    />
                    <label className="form-check-label" htmlFor="radio-yes">Yes</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      // style={{ marginRight: "-150px" }}
                      id="radio-no"
                      type="radio"
                      name="citizenship"
                      value={"N"}
                      checked={usCitizen === "N"}
                      onChange={handleUsCitizenshipChange}
                    />
                    <label className="form-check-label" htmlFor="radio-no">No</label>
                  </div>
                  <div>
                    {inputusCitizen && (
                      <p style={{ color: 'red', marginLeft:'1px' }}>
                        Selecting a US Citizen value is required
                      </p>
                    )} </div>
                </div>
              </div>
            </div>

            {isGenderOther && (
              <div className="row">
                <div className="col-5 flex-fill ms-4 pe-5">
                  <label className="label-div mb-1 asterisk">Enter Other Gender</label>
                  <input
                    className="form-control input-group-lg ms-3 me-3"
                    type="textbox"
                    style={{ width: "100%" }}
                    placeholder="Enter Other Gender"
                  />
                </div>
              </div>
            )}
          </div>
          {/* <div className="row" style={{ margin: "1em" }}> */}

          {isNotUsCitizen && (
            <div style={{ marginBottom: "1em"}}>
              <div className="row row-cols-2">
                <div className="col-5 flex-fill ms-4">
                  <label className="label-div asterisk">
                    US Lawful Permanent Resident
                  </label>
                  <HelpDialog
                    title={"U.S. Lawful Permanent Resident?"}
                    context={textLawful}
                  />
                  <div className="control-div">
                    <div className="control-div form-check form-check-inline">
                      <input
                        // style={{ marginLeft: "-10px" }}
                        id="residency-yes"
                        type="radio"
                        name="residency"
                        value={"Y"}
                        checked={permResident === "Y"}
                        onChange={handlePermResidentChange}
                      />
                      <label htmlFor="residency-yes">Yes</label>
                    </div>
                    <div className=" form-check form-check-inline">
                      <input
                        id="residency-no"
                        type="radio"
                        name="residency"
                        value={"N"}
                        checked={permResident === "N"}
                        onChange={handlePermResidentChange}
                      />
                      <label htmlFor="residency-no">No</label>
                    </div>
                  </div>
                </div>
                {isLawfulResident && (
                  <div className="col-6 ms-5 me-2">

                    <label className="label-div asterisk mb-1 ms-3">
                      Lawful Permanent Resident Card Number #
                    </label>
                    <div className="row" style={{ marginLeft: ".25em" }}>
                      <div className="col">
                        <UploadControl
                          handleTextboxValueChange={
                            handlePermResidentIdNumberChange
                          }
                          textboxValue={primeGovIdentNum}
                          title={"Upload Document"}
                          content={"Upload Lawful Permanent Resident ID Card:"}
                          text={"*Upload Lawful Resident Card Photo ID"}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          field="isLawfulResident"
                          isUploaded={uploadStatus.isLawfulResident}
                          
                        ></UploadControl>

                      </div>

                    </div>
                  </div>
                )}

              </div> { /* end of Row */}
            </div>
          )}
          {/* </div> */}
          {isEitherCitzenOrResident && (
            <label className="flex-fill" style={{ marginLeft: "3em", marginBottom: "10px", }}>
              {/* If you are a US Citizen or US legal permanent resident, you must
                provide your US passport number or permanent resident card number. */}
            </label>
          )}
          <div style={{ marginBottom: "1em" }}>
            <div className="textFormat row">
              <div className="col-9 ms-3">
                <label> Citizenship </label>
                <HelpDialog
                  title={"Citizenship"}
                  context={textCitizenship}
                />
              </div>
            </div>
            <div className="rounded-2 contentSection textFormat">
              <div className="row row-cols-2">
                <div className="col-6">
                  <label className="label-div asterisk mb-1">
                    Country of Citizenship
                  </label>
                  <div className="control-div">
                    <select className="form-select form-select-md" value={selectedCountry} onChange={handleCountrySelectChange}>
                      <option value="" disabled hidden selected>
                        Select Country of Citizenship
                      </option>
                      {countries?.map((item, index) => (
                        <option key={index} value={item.country_name}>{item.country_name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {inputCoutryofCitizenship && (
                      <p style={{ color: 'red', marginLeft: "1em" }}>
                        Country of Citizenship is required
                      </p>
                    )} </div>
                </div>

                <div className="col flex-fill pe-5">
                  <label className="label-div asterisk mb-1 ps-0 ms-0">
                    Government Identification Type
                  </label>
                  <div className="control-div">
                    <select className="form-select form-select-md" value={selectedGovernmentIdentification} onChange={handleGovernmentIdentificationTypeChange}>
                      <option selected> Select Government Identity type</option>
                      {orgIdentityTypes?.map((item, index) => (
                        <option key={index} value={item.display_name}>{item.display_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    {inputGovernmentIdentificationType && (
                      <p style={{ color: 'red', marginRight: '70px' }}>
                        Government Identification Type is required
                      </p>
                    )} </div>
                </div>
              </div>
              <div className="row row-cols-2">
                <div className="col-6 pe-0">
                  <label className="label-div asterisk mb-1">
                    Government-Issued Photo ID #
                  </label>
                  <div style={{ marginLeft: "1em" }}>
                    <UploadControl
                      size="large"
                      type="textbox"
                      maxLength={38}
                      placeholder="Enter Government-Issued Photo ID #"
                      handleTextboxValueChange={handleIdNumberChange}
                      textboxValue={idNumber}
                      title={"Upload Document"}
                      content={"Upload Government Issued Photo ID:"}
                      text={"*Upload ID"}
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      field="field2"
                      isUploaded={uploadStatus.citizenship}
                    > </UploadControl>
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    {
                    
                    inputIdNumber && (
                      <p style={{ color: 'red', marginRight: '70px' }}>
                        Government Issued Photo ID # is required
                      </p>
                    
                    )
                  } </div>

                </div>
                <div className="col flex-fill pe-5">
                  <label className="label-div asterisk mb-1">
                    Country Issued By
                  </label>
                  <div className="control-div">
                    <select className="form-select form-select-md"  value={primCitCountryIssuedBy} onChange={handlePrimaryCountryIssuedBy}>
                      <option value="" disabled hidden selected>
                        Select Country Issued By
                      </option>
                      {countries?.map((item, index) => (
                        <option key={index} value={item.country_name}>{item.country_name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {inputIDCountryOfIssuance && (
                      <p style={{ color: 'red', marginLeft: "1em" }}>
                        Country Issued By is required
                      </p>
                    )} </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <div className="textFormat row mt-3 mb-1"> */}
            <div className="textFormat row">
              <div className="col-9 ms-3">
                <label>Place of Birth</label>
                <HelpDialog
                  title={'Place of Birth'}
                  context={textBirth}
                />
              </div>
            </div>
            <div className="rounded-2 mb-3 contentSection textFormat">
              <div className="row row-cols-2 mb-2">
                <div className="col-5 ms-2">
                  <label className="label-div asterisk mb-1">Village/City</label>
                  <div className="control-div w-100">
                    <input
                      className="form-control input-group-lg"
                      placeholder="Enter Village/City"
                      maxLength={150}
                      type="textbox"
                      onChange={handleCityVillageChange}
                      value={cityVillage}
                    />
                  </div>
                  <div>
                    {inputcityVillage && (
                      <p style={{ color: 'red' }}>
                        Village/City is required
                      </p>
                    )} </div>
                </div>
                <div className="col flex-fill ms-4 me-5">
                  <label className="label-div asterisk ps-1 mb-1">District (If no District, please enter N/A)</label>
                  <div className="control-div">
                    <input
                      className="form-control input-group-lg mb-1"
                      placeholder="Enter District"
                      maxLength={150}
                      type="textbox"
                      onChange={handleDistrictChange}
                      value={district}
                    />
                  </div>
                  <div>
                    {inputDistrict && (
                      <p style={{ color: 'red', marginLeft: '20px' }}>
                        District is required
                      </p>
                    )} </div>
                </div>
              </div>
              {/* <div className="row row-cols-3"> */}
              <div className="d-flex flex-grow-0 flex-shrink-0">
                <div className="col ms-2">
                  <label className="label-div asterisk mb-1">
                    Governorate, Province, or State
                  </label>
                  <div className="control-div">
                    <input
                      className="form-control input-group-lg mb-2"
                      placeholder="Enter Governorate, Province, or State"
                      maxLength={150}
                      type="textbox"
                      onChange={handleGovernorateChange}
                      value={governorate}
                    />
                  </div>
                  <div>
                    {inputGovernorate && (
                      <p style={{ color: 'red' }}>
                        Governorate, Province, or State is required
                      </p>
                    )} </div>
                </div>
                <div className="col ms-2">
                  <label className="label-div asterisk mb-1">
                    Country of Birth
                  </label>
                  <div className="control-div">
                    <select className="form-select form-select-md mb-2" value={birthCountry} onChange={handleCountrySelectBirthCountry}>
                      <option value="" disabled hidden selected>
                        Select Country of Birth
                      </option>
                      {countries?.map((item, index) => (
                        <option key={index} value={item.country_name}>{item.country_name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {
                    inputCountryOfBirth && (
                      <p style={{ color: 'red', marginLeft: '20px' }}>
                        Country of Birth is required
                      </p>
                    )
                  } </div>
                </div>
                <div className="col">
                  <label className="label-div asterisk mb-1">Date of Birth</label>
                  <div className="mb-2">
                    <DatePicker format={('dd-MMM-yyyy')}
                      style={{ width: "100%", color: "black", fontFamily: "sans-serif"}}
                      size='md'
                      id='dateOfBirth'
                      value={dob}
                      onChange={handleDobChange}
                      placeholder="dd-mmm-yyyy"
                      placement='topStart'
                    />
                    <div>
                      {
                        inputDateOfBirth && (
                        <p style={{ color: 'red', marginLeft: '20px' }}>
                          Date of Birth is required
                        </p>
                      )} 
                      </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="control-div">
            <div className="col-3 ms-4">
              <label className="label-div asterisk mb-1">Dual Citizenship</label>
              <HelpDialog
                title={"Dual Citizen"}
                context={textDual}
              />

              <div className="row control-div">
                <div className="d-flex align-items-center col-2 mx-0 form-check form-check-inline">
                  <input
                    style={{ marginLeft: "1em" }}
                    id="dual-yes"
                    type="radio"
                    name="dualCitizenship"
                    value={"Y"}
                    checked={dualCitizenship === "Y"}
                    onChange={handleDualCitizenshipChange}
                  />
                  <label htmlFor="dual-yes">Yes</label>
                </div>
                <div className="d-flex align-items-center col-2 mx-0 form-check form-check-inline">
                  <input
                    // style={{ marginLeft: "1em", }}
                    id="dual-no"
                    type="radio"
                    name="dualCitizenship"
                    value={"N"}
                    checked={dualCitizenship === "N"}
                    onChange={handleDualCitizenshipChange}
                  />
                  <label htmlFor="dual-no">No</label>
                </div>
              </div>
              <div>
                {inputDualCitizenship && (
                  <p style={{ color: 'red' }}>
                    Selecting a Dual Citizenship value is required
                  </p>
                )} </div>
            </div>
          </div>
        
          <div>
            <DualCitizenship
              hasDualCitizenship={dualCitizenship}
              title="Dual Citizenship"
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setCountryData={countries}
              setIdentityData={orgIdentityTypes}
              value={otherCitizenships}
              onChange={handleOtherCitizenshipChange} disabled={false}            />
          </div>
          
          <div>
            {/* Put these validation messages in Additional Address */}
            {
            inputPrimaryCountry && (
              <p style={{ color: 'red', marginTop: '45px', marginLeft: '20px' }}>
                Country of Residence is required
              </p>
            )}</div>

          <div>
            {inputPrimaryResidence && (
              <p style={{ color: 'red', marginLeft: '20px' }}>
                Address of Residence is required
              </p>
            )} </div>
          <div>
            {inputCity && (
              <p style={{ color: 'red', marginLeft: '20px' }}>
                City is required
              </p>
            )} </div>
          <div>
            {inputPrimaryState_region && (
              <p style={{ color: 'red', marginLeft: '20px' }}>
                State/Province/Region is required.
              </p>
            )} </div>
          <div>
            {inputPrimaryZipCode && (
              <p style={{ color: 'red', marginLeft: '20px' }}>
                Zip Code is required
              </p>
            )} </div>

          <div>
            <AdditionalAddress
              mainTitle={"Residence Address"}
              subTitle={""}
              controlAttributes={addressControls}
              countryData={countries} disabled={false}            />
          </div>
          
          <div className="rounded-2 mb-3 contentSection textFormat" style={{ marginTop: '2em' }}>
            <div className="row row-cols-2 ms-3 mb-2">
              <div className="col-5">
                <AdditionalIsCellPhoneNumber
                  key={radioBtnKey}
                  btnName="btnOne"
                  title={"Primary Phone Number"}
                  isCell={isCellPhone}
                  isAddtionalCell={isAdditionalCellPhone}
                  onIsCellChange={handleIsCellPhoneChange}
                  onIsAddtionalCellChange={handleIsAdditionalCellPhoneChange}
                  controlAttributes={phoneControls}
                  isAddNumberNeeded={false}
                  isPrimary={true} disabled={false}                />
                <div>
                  {inputPhoneOne && (
                    <p style={{ color: 'red', marginLeft: '20px' }}>
                      {inputPhoneOne}
                    </p>
                  )} </div>
              </div>

              <div className="col flex-fill ms-5">
                <AdditionalIsCellPhoneNumber
                  key={radioBtnKey}
                  btnName="btnTwo"
                  title={"Alternative Phone Number"}
                  isCell={isCellPhone}
                  isAddtionalCell={isAdditionalCellPhone}
                  onIsCellChange={handleIsAdditionalCellPhoneChange}
                  onIsAddtionalCellChange={handleIsAdditionalCellPhoneChange}
                  controlAttributes={phoneControls2}
                  isAddNumberNeeded={false}
                  isPrimary={false} disabled={false}                />
                <div>
                  {inputPhoneTwo && (
                    <p style={{ color: 'red', marginLeft: '20px' }}>
                      {inputPhoneTwo}
                    </p>
                  )} </div>
              </div>
            </div>


            <div className="row row-cols-2 mb-1">
              <div className="col-5 ms-5 me-2">
                <AdditionalEmail
                  title={"Primary Personal Email Address"}
                  isAddEmailNeeded={false}
                  controlAttributes={emailControls}
                  isRedStarNeeded={true}
                  isPrimary={true}
                  isPersonal={true} disabled={false}                />
                <div>
                  {inputPrimaryEmail && (
                    <p style={{ color: 'red' }}>
                      {inputPrimaryEmail}
                    </p>
                  )} </div>
              </div>

              <div className="col flex-fill ms-4 pe-5">
                <AdditionalEmail
                  title={"Alternate Email"}
                  isAddEmailNeeded={false}
                  controlAttributes={emailControls2}
                  isRedStarNeeded={false}
                  isPrimary={false}
                  isPersonal={false} disabled={false}                />
                <div>
                  {inputAlternateEmail && (
                    <p style={{ color: 'red' }}>
                      {inputAlternateEmail}
                    </p>
                  )} </div>
              </div>
            </div>

            <div className="row row-cols-2">
              <div className="col-5 ms-4">
                <label className="label-div ms-4 mb-1">
                  Tribal Affiliation (if applicable)
                </label>
                <div className="row control-div">
                  <input
                    className="form-control ms-4 input-group-lg"
                    maxLength={150}
                    placeholder="Enter Tribal Affiliation"
                    type="textbox"
                    onChange={handleTribalChange}
                    value={tribal}
                  />
                </div>
              </div>

              <div className="col flex-fill ms-4">
                <label className="label-div asterisk mb-1">
                  Organization (Current Employer)
                </label>
                <div className="control-div ms-3">
                  <input
                    className="form-control input-group-lg"
                    maxLength={150}
                    placeholder="Select Current Employer"
                    type="textbox"
                    onChange={handleOrganizationNameChange}
                    value={organizationName}
                  />
                </div>
                <div>
                  {inputOrganizationName && (
                    <p style={{ color: 'red', marginLeft: '20px' }}>
                      Organization name is required
                    </p>
                  )} </div>
              </div>
            </div>

            <div className="row row-cols-2">
              <div className="col-5 ms-5">
                <AdditionalLicense
                  title={"Professional Licenses & Certifications"}
                  controlAttributes={licenseControls} disabled={false}                />
              </div>
              <div className="col flex-fill">
                <label className="label-div mb-1">Organization Title or Rank</label>
                <div className="control-div">
                  <input
                    className="form-control input-group-lg"
                    maxLength={200}
                    type="textbox"
                    onChange={handleRankChange}
                    value={rank}
                    placeholder="Enter Organization Role/Title/Rank"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-5 px-1 d-flex justify-content-center gap-4 flex-sm-col flex-wrap align-items-center" style={{marginTop: '20px'}}>
      
      {isUpdateVisible && (
       <button className="btn loginButton" onClick={deleteEvent}>Delete Key Individual</button> 
      )}
        <button className="btn loginButton" onClick={cancelEvent}>Cancel</button>
        <button className="btn loginButton" onClick={saveEvent} disabled={disableFormFields} 
                style={{display: showDetails ? "block" : "none"}}>Save Key Individual</button>
        <button className="btn loginButton" onClick={continueEvent} 
                style={{display: showDetails ? "none" : "block"}} disabled={disableContinue}>Continue</button>

                {isUpdateVisible && (
          <button className="btn loginButton" onClick={updateSelectedIndi}>Update Individual</button>
        )}

      </div>
    </div>
  );
}
